import React from "react";
import TopBanner from "../components/TopBanner";
import HowItWorks from "./HowItWorks";
import HaveQuestions from "./HaveQuestions";
import WantToKnowMore from "./WantToKnowMore";
import BecomeRecognized from "../components/BecomeRecognized";

function RequirementsMain() {
  return (
    <div className="bg-[#F9FAFB]">
      <TopBanner imgBg={"/RequirementsPageImg-Header.png"} center={false} />
      <HowItWorks />
      <HaveQuestions />
      <WantToKnowMore />
      <BecomeRecognized />
    </div>
  );
}

export default RequirementsMain;