import React from "react";
import Accordion from "./Accordion";

function WantToKnowMore() {
  return (
    <div className="mt-20 max-w-main m-auto px-10 max-md:px-3">
      <div className=" font-bold max-md:text-sm20 text-[30px] text-devRegDarkBlue mt-10">
        WANT TO KNOW MORE?
      </div>
      <div className="text-[22px] text-black70 max-md:text-sm16 mb-5">
        Check our FAQ Below
      </div>

      <Accordion />
    </div>
  );
}

export default WantToKnowMore;