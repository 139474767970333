import React, { useState, useEffect, useRef } from "react";
import BacktoLogin from "../components/BackToLogin";
import { FaPaste } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../actions";
import {
    enable2FA,
    verifyOTP2FA,
    generatetRecoveryCodes,
} from "../../../api/apiCall";
import QRCode from "qrcode.react";
import Modal from "react-modal";
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '600px',
        background: 'white',
        border: 'none',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
        maxHeight: '95vh',
        overflowY: 'auto', 
        padding: '20px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'none',
    },
};

function QRScan() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [qrCodeUrl, setQrCodeUrl] = useState("");
    const [qrCodeSecret, setQrCodeSecret] = useState("");
    const [otpOne, setOtpOne] = useState("");
    const [otpTwo, setOtpTwo] = useState("");
    const [otpThree, setOtpThree] = useState("");
    const [otpFour, setOtpFour] = useState("");
    const [otpFive, setOtpFive] = useState("");
    const [otpSix, setOtpSix] = useState("");
    // const [buttonDisabled, setButtonDisabled] = useState(true);
    const [error, setError] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const [chosenWay, setChosenWay] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);
    let swiperRef = null;
    const [countdown, setCountdown] = useState(5);
    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    useEffect(() => {
        handleEnable2FA();
    }, []);

    const handleEnable2FA = async () => {
        try {
            let data = {
                id: user.id,
                token: token,
            };

            const response = await enable2FA(data);
            if (response.status === 200) {
                setQrCodeUrl(response.data.qr_code_url);
                setQrCodeSecret(response.data.secret);
            }
        } catch (error) {
            console.error("Error enabling 2FA: ", error);
        }
    };

    useEffect(() => {
        const allFilled = [otpOne, otpTwo, otpThree, otpFour, otpFive, otpSix].every(value => value.length === 1);
        // setButtonDisabled(!allFilled);

        if (allFilled) {
            handleVerify();
        }
    }, [otpOne, otpTwo, otpThree, otpFour, otpFive, otpSix]);

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (value.length > 1) {
            e.target.value = value.slice(0, 1);
        }
        const otpSetters = [setOtpOne, setOtpTwo, setOtpThree, setOtpFour, setOtpFive, setOtpSix];
        otpSetters[index](value);

        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            if (text.length === 6 && /^[0-9]+$/.test(text)) {
                const otpSetters = [setOtpOne, setOtpTwo, setOtpThree, setOtpFour, setOtpFive, setOtpSix];
                text.split("").forEach((char, index) => {
                    otpSetters[index](char);
                    inputRefs[index].current.value = char;
                });
                inputRefs[5].current.focus();
            } else {
                console.error("Invalid OTP format. Make sure it's 6 digits.");
            }
        } catch (error) {
            console.error("Failed to read clipboard contents: ", error);
        }
    };

    const handleVerify = async () => {
        dispatch(setIsLoading(true));
        try {
            let data = {
                id: user.id,
                token: token,
                payloads: {
                    email: user.email,
                    otp: `${otpOne}${otpTwo}${otpThree}${otpFour}${otpFive}${otpSix}`
                }
            };

            const response = await verifyOTP2FA(data);
            if (response.status === 200) {
                const generateResult = await handleGenerateRecoveryCodes();
                if (generateResult === true) {
                    navigate("/recovery-codes");
                } else {
                    console.error("Failed to generate recovery codes: ", generateResult.response.data.message);
                }
            } else {
                console.error("Failed to verify OTP: ", response.message);
                setError('Invalid OTP!');
            }
        } catch (error) {
            console.error("Error verifying your OTP: ", error);
            setError('Invalid OTP!');
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const handleGenerateRecoveryCodes = async () => {
        try {
            let data = {
                id: user.id,
                token: token,
                payloads: {
                    otp: `${otpOne}${otpTwo}${otpThree}${otpFour}${otpFive}${otpSix}`,
                }
            };

            const response = await generatetRecoveryCodes(data);
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            console.log("Error getting recovery codes: ", error);
            return error;
        }
    };

    const handleNextMobile = () => {
        if (activeSlide === mobileApproach.length - 1) {
          setModalIsOpen(false);
        } else {
        //   swiperRef.slideNext();
            setActiveSlide(activeSlide + 1);
            setCountdown(5);
        }
    };

    const handleNextExtension = () => {
        if (activeSlide === extensionApproach.length - 1) {
          setModalIsOpen(false);
        } else {
        //   swiperRef.slideNext();
            setActiveSlide(activeSlide + 1);
            setCountdown(5);
        }
    };

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    const goToMobileAppLink = () => {
        window.open("https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2");
    }

    const goToExtensionLink = () => {
        window.open("https://chromewebstore.google.com/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en");
    }

    const mobileApproach = [
        { step: 1, img: "/2fa/m1.jpg", desc: "Download the Google Authenticator Mobile Application from google play store." },
        { step: 2, img: "/2fa/m2.jpg", img2: "/2fa/m2-2.jpg", desc: "Add new code for authentication." },
        { step: 3, img: null, desc: "Scan the Devreg 2FA QR that is displayed in the screen. Make sure to point the QR inside the scanner box." },
        { step: 4, img: "/2fa/m4.jpg", desc: "Now get the OTP from your Google Authenticator Application." },
        { step: 5, img: "/2fa/m5.png", desc: "Enter the OTP. Take note that the OTP expires in a seconds." },
    ];

    const extensionApproach = [
        { step: 1, img: "/2fa/c1.png", desc: "Download the Authenticator extension in your Chrome." },
        { step: 2, img: "/2fa/c2.png", desc: "Open the Authenticator extension." },
        { step: 3, img: "/2fa/c3.png", desc: "Click the scan qr icon in the top-right corner of the authenticator extension." },
        { step: 4, img: "/2fa/c4.png", desc: "Scan the qr code. Make sure that the qr code is completely included in teh box." },
        { step: 5, img: "/2fa/c5.png", desc: "Open the Authenticator again to get the OTP. Take note that the OTP expires in a seconds." },
        { step: 6, img: "/2fa/c6.png", desc: "Enter the OTP to the inputs, or you can just paste it by just clicking the 'Paste from Clipboard' button." },
        { step: 7, img: "/2fa/c7.png", desc: "And now you can get your recovery codes. Make sure to save your recovery codes to a place where you can easily find it. You can use these recovery codes to your future logins." },
    ];

    return (
        <div className="flex flex-col items-center px-20 py-20 text-base bg-devRegDarkBlue min-h-screen flex-grow max-md:px-5">
            <style>
                {
                    `
                    .ReactModal__Overlay {
                        animation: fadeIn 0.7s;
                    }

                    @keyframes fadeIn {
                        from {
                            opacity: 0;
                        }
                        to {
                            opacity: 1;
                        }
                    }
                    `
                }
            </style>
            <div className="flex flex-col p-6 mt-6 max-w-full bg-white rounded-3xl shadow-sm w-[400px] max-md:px-5">
                <div className="flex items-center justify-between w-full mb-4">
                    <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
                        <BacktoLogin />
                    </div>
                    <img
                        src="/logoFooter.png"
                        alt="Devreg Logo"
                        className="h-8"
                    />
                </div>
                <hr className="w-full border-t border-gray-200" />
                <div className="flex flex-col justify-center mt-6 w-full">
                    <div className="text-xl font-semibold text-center text-neutral-800">
                        Scan QR Code
                    </div>
                    <div className="flex flex-col mt-3 w-full">
                        <div className="flex gap-2.5 justify-center items-start w-full">
                            <QRCode value={qrCodeUrl} loading="lazy" className="w-48 h-48 border-8 border-teal-300" />
                        </div>
                        <div className="self-center mt-5 mb-2 w-full text-base leading-5 text-center rounded-none text-zinc-700 text-opacity-80">
                            Scan this code using your authenticator app to get the authentication code.
                        </div>
                    </div>
                </div>
                {error && (
                    <small className="text-red-600 text-center">{error}</small>
                )}
                <div className="flex gap-4 mt-2 w-full items-center justify-center">
                    {[otpOne, otpTwo, otpThree, otpFour, otpFive, otpSix].map((otp, index) => (
                        <input
                            key={index}
                            type="text"
                            className={`flex-1 shrink-0 px-3 rounded-md border border-solid h-[46px] w-[30px] max-[320px]:px-0 text-center ${error ? 'border-red-600' : 'border-zinc-700 border-opacity-20'}`}
                            maxLength={1}
                            value={otp}
                            onChange={(e) => handleInputChange(e, index)}
                            ref={inputRefs[index]}
                        />
                    ))}
                </div>
                <div
                    onClick={handlePaste}
                    className="w-full flex flex-row gap-1 cursor-pointer justify-center items-center px-9 py-3 mt-7 text-base font-semibold text-white whitespace-nowrap rounded-3xl max-md:px-5 text-center bg-teal-300"
                >
                    <FaPaste size={20} />
                    Paste from Clipboard
                </div>
                <div
                    onClick={() => handleVerify()}
                    className="w-full cursor-pointer justify-center items-center px-9 py-3 mt-4 text-base font-semibold text-white whitespace-nowrap rounded-3xl max-md:px-5 text-center bg-blue-900"
                >
                    Verify
                </div>
            </div>
            
            {/* Modal Section */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={customStyles}
                contentLabel="Carousel Modal"
                shouldCloseOnOverlayClick={false}
                >
                <div className="flex justify-start items-center mb-6">
                    <div className="text-lg font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full">
                    Step by Step Guide
                    </div>
                </div>

                {/* Swiper Section */}
                <Swiper
                    modules={[Navigation, Pagination]}
                    pagination={{ clickable: true }}
                    spaceBetween={50}
                    slidesPerView={1}
                    autoplay={{ delay: 2500, disableOnInteraction: false }}
                    onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
                    onSwiper={(swiper) => (swiperRef = swiper)}
                >
                    {chosenWay === null && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <h1 className="text-2xl text-center max-[320px]:text-[20px]">Choose your preference for Two Factor Authentication</h1>
                            <div className="flex gap-3 justify-center items-center w-full mt-10 items-start">
                                <div className="flex flex-col">
                                    <button
                                        onClick={() => { setChosenWay("mobile"); setCountdown(5);}}
                                        className="px-4 py-2 max-[320px]:p-1 max-[320px]:text-[12px]  bg-blue-500 text-white rounded hover:bg-blue-700 transition"
                                    >
                                        Mobile Application
                                    </button>
                                    <small className="text-center text-green-600 max-[320px]:text-[12px]">Recommended</small>
                                </div>
                                <div className="self-start">
                                    <button
                                        onClick={() => {setChosenWay("extension"); setCountdown(5);}}
                                        className="px-4 py-2 max-[320px]:p-1 max-[320px]:text-[12px] ml-4 bg-blue-500 text-white rounded hover:bg-blue-700 transition"
                                    >
                                        Chrome Extension
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {chosenWay === "mobile" && (
                        mobileApproach.map((item, index) => (
                            activeSlide === index && (
                                <SwiperSlide key={index}>
                                    <div className="flex max-md:flex-col justify-center gap-2">
                                        {item.img && (
                                            <img src={item.img} alt={`Slide ${index + 1}`} className="max-h-[400px] mx-auto" />
                                        )}
                                        {item.img2 && (
                                            <img src={item.img2} alt={`Slide ${index + 1}`} className="max-h-[400px] mx-auto" />
                                        )}
                                    </div>
                                    <b>Step {item.step}:</b>
                                    <p className="text-start">{item.desc}</p> 
                                    {item.step === 1 && (
                                        <div className="mt-2 text-center">
                                            <img className="w-[100px] h-[100px] mx-auto" src="/2fa/mobileAppQR.jpg" alt="" />
                                            <p>Scan this QR Code to download the Application. Or <button onClick={goToMobileAppLink} className="text-blue-500 underline">click here</button></p>
                                        </div>
                                    )}
                                </SwiperSlide>
                            )
                        ))
                    )}
                    {chosenWay === "extension" && (
                        extensionApproach.map((item, index) => (
                            activeSlide === index && (
                                <SwiperSlide key={index}>
                                    <img src={item.img} alt={`Slide ${index + 1}`} className="w-full" />
                                    <b>Step {item.step}:</b>
                                    <p className="text-start">{item.desc}</p> {item.step === 1 && (
                                        <button className="text-blue-500 underline" onClick={goToExtensionLink}>
                                            Click here to download
                                        </button>
                                    )}
                                </SwiperSlide>
                            )
                        ))
                    )}
                                    
                </Swiper>

                {/* Next/Finish Button */}
                {chosenWay !== null && (
                    <div className="flex justify-end mt-4">
                        {chosenWay === "mobile" && (
                            <button
                            onClick={handleNextMobile}
                            className={`px-4 py-2 bg-blue-700 text-white rounded transition ${countdown > 0 ? 'cursor-not-allowed opacity-[50%]':'hover:bg-blue-800 cursor-pointer'}`}
                            disabled={countdown > 0}
                            >
                                {countdown > 0 ? `Next in ${countdown}` : activeSlide === mobileApproach.length - 1 ? "Finish" : "Next"}
                            </button>
                        )}
                        
                        {chosenWay === "extension" && (
                            <button
                            onClick={handleNextExtension}
                            className={`px-4 py-2 bg-blue-700 text-white rounded transition ${countdown > 0 ? 'cursor-not-allowed opacity-[50%]':'hover:bg-blue-800 cursor-pointer'}`}
                            disabled={countdown > 0}
                            >
                                {countdown > 0 ? `Next in ${countdown}` : activeSlide === extensionApproach.length - 1 ? "Finish" : "Next"}
                            </button>
                        )}
                    </div>
                )}
                
            </Modal>

        </div>
    );
}

export default QRScan;
