import React, { useEffect } from "react";
import { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { motion } from "framer-motion";
function WhyRegisterSwiper() {
  const [slidesPerView, setSlidesPerView] = useState(2);

  const content = [
    {
      title: "Boost Your Reputation",
      id: 1,
      description:
        "Your verified status can significantly boost your professional reputation. Our robust validation process ensures that all members of our platform are recognized for their genuine skills and qualifications.",
    },

    {
      title: "Unlimited Opportunities",
      id: 2,
      description:
        "Our platform is frequented by potential clients and collaborators looking for trustworthy professionals like you. Get access a plethora of new projects and partnerships, giving your career the lift it needs.",
    },

    {
      title: `Increase
      Trust`,
      id: 3,
      description:
        "Demonstrate commitment to transparency and trustworthiness as verified professionals. This enhanced credibility can lead to stronger client relationships and more opportunities.",
    },
    {
      title: "Build Communities",
      id: 4,
      description:
        "Join a network of like-minded individuals, where you can collaborate, share ideas, and foster professional relationships.",
    },
  ];
  useEffect(() => {
    function handleResize() {
      const newSlidesPerView = window.innerWidth >= 768 ? 2 : 1;
      setSlidesPerView(newSlidesPerView);
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Swiper
      centeredSlides={true}
      spaceBetween={25}
      slidesPerView={slidesPerView}
      className="mySwiper mt-5 "
    >
      {content.map((item, index) => (
        <SwiperSlide key={index}>
          <motion.div className="w-full border-[3px] border-devRegDarkBlue rounded-t-lg">
            <div
              className={`border-devRegDarkBlue shadow-lg border-b-[7px] m-auto w-full h-[350px] overflow-y-hidden 
                bg-white  text-devRegBlack relative px-[32px] py-[32px] cursor-pointer rounded-t-lg`}
              style={{ transition: "background-color 0.3s, color 0.3s" }}
            >
              <div className="flex flex-col">
                <div className="flex gap-5 mb-4 flex-col">
                  <div>
                    <img src="/checkboxRegister.png" alt="" />
                  </div>
                  <div className="text-2xl font-semibold tracking-tighter">
                    {item.title}
                  </div>
                </div>
                <p
                  className={`text-[#626262] 
                h-auto`}
                >
                  {item.description}
                </p>
              </div>
            </div>
          </motion.div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default WhyRegisterSwiper;
