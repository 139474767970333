import React from "react";

function OurVision() {
  return (
    <div className="w-full max-w-main max-md:mt-10 max-md:max-w-full max-md:px-0 m-auto px-10">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0  bg-devRegDarkBlue px-20 max-md:px-5 py-8 rounded-2xl max-md:rounded-none">
        <div className="flex flex-col w-[27%] max-md:ml-0 max-md:w-full">
          <img
            alt="img"
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&"
            className=" w-full aspect-[0.85] max-md:mt-10 max-sm:w-[210px] max-sm:aspect-[0.90] m-auto max-sm:mt-20 max-md:w-[280px] max-md:aspect-[0.87] "
          />
        </div>
        <div className="flex flex-col ml-5 w-[73%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col justify-center items-start self-stretch px-5 py-8 max-sm:py-4  max-md:mt-0 my-auto text-2xl tracking-tight max-sm:mt-0 max-md:max-w-full max-md:text-center">
            <div className="self-stretch text-base tracking-tighter leading-6 text-neutral-600 max-md:max-w-full ">
              <span className="text-3xl font-bold tracking-tighter leading-10 text-white max-md:text-center">
                Our Vision for Clients and Professionals
              </span>
              <br />
              <br />
              <span className="leading-7 text-white">
                We aim to unite clients and developers on a foundation of
                integrity, honesty, and authenticity. We strive for an
                environment where every user, whether offering or seeking
                services, is met with respect, care, and professionalism. Our
                mission is to cultivate genuine, prosperous relationships
                between clients and developers through mutual trust and shared
                success. Join us in realizing this vision.
              </span>
            </div>
            <div className="mt-9 font-bold text-devRegGreen leading-[120%] max-md:w-full max-md:text-center">
              Jeffrey Lawson
            </div>
            <div className="mt-2 font-semibold text-white leading-[100%] max-md:w-full max-md:text-center">
              CEO
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurVision;