import React from "react";

function CardWhy() {
  const content = [
    {
      title: "Boost Your Reputation",
      id: 1,
      description:
        "Your verified status can significantly boost your professional reputation. Our robust validation process ensures that all members of our platform are recognized for their genuine skills and qualifications.",
    },
    {
      title: "Unlimited Opportunities",
      id: 2,
      description:
        "Our platform is frequented by potential clients and collaborators looking for trustworthy professionals like you. Get access a plethora of new projects and partnerships, giving your career the lift it needs.",
    },
    {
      title: `Increase Trust`,
      id: 3,
      description:
        "Demonstrate commitment to transparency and trustworthiness as verified professionals. This enhanced credibility can lead to stronger client relationships and more opportunities.",
    },
    {
      title: "Build Communities",
      id: 4,
      description:
        "Join a network of like-minded individuals, where you can collaborate, share ideas, and foster professional relationships.",
    },
  ];

  return (
    <div className="flex flex-col items-center mt-5">
      <div className="flex justify-center gap-8">
        {content.map((item, index) => (
          <div key={index}>
            <div className="border-[3px] border-devRegDarkBlue rounded-t-lg">
              <div
                className={`border-devRegDarkBlue shadow-lg border-b-[7px] w-[290px] h-[340px] overflow-y-hidden 
                  bg-white text-devRegBlack relative px-[12px] py-[32px] cursor-pointer rounded-t-lg`}
                style={{ transition: "background-color 0.3s, color 0.3s" }}
              >
                <div className="flex flex-col">
                  <div className="flex gap-5 mb-4 flex-col">
                    <div>
                      <img src="/checkboxRegister.png" alt="" />
                    </div>
                    <div className="text-2xl font-semibold tracking-tighter">
                      {item.title}
                    </div>
                  </div>
                  <p className="text-[#626262] h-auto">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardWhy;