import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function UnlockingOpportunities() {

  const navigate = useNavigate();
  return (
    <section className="mt-28 max-md:mt-16 flex justify-around max-md:flex-col min-[768px]:px-4 m-auto">
      <img
        src="/UnlockingOpportunities.png"
        alt="unlocking"
        className="max-md:w-full max-h-[500px] bg-center  min-[768px]:rounded-xl"
      />
      <div className="px-4 max-w-[700px]">
        <div className=" max-md:text-[20px]  max-md:mt-7 text-devRegDarkBlue font-bold text-[30px] leading-9 ">
          Unlocking Opportunities and Trust with Devreg.org
        </div>
        <p className="text-black70  max-md:mt-7 mt-10 max-md:text-[14px]">
          DevReg.org: Empowering Software Developers with a Secure,
          Trust-Building Platform. Register to showcase your skills, expand your
          global network, and gain recognition as a reliable, accredited
          professional.
        </p>
        <p className="text-black70  max-md:mt-7 mt-5 max-md:text-[14px]">
          This platform transcends a mere badge – it's your gateway to forming
          strong connections and advancing your career in the international
          software development community. Join DevReg.org for unparalleled
          professional opportunities and growth.
        </p>
        <div className="flex max-md:justify-end max-md:mt-7 mt-10 max-[425px]:w-full">
          <button onClick={() => {
                navigate("/why-register");
                window.scrollTo(0, 0);
              }} className="flex justify-center items-center my-auto px-5 py-3 rounded-full text-white bg-devRegDarkBlue font-bold max-md:w-full">
            Learn More
            <span>
              <IoIosArrowForward className="text-white text-[20px] ml-2" />
            </span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default UnlockingOpportunities;