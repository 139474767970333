import React, { useEffect } from "react";
import { useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { motion } from "framer-motion";

function MidHeroCard() {
  const [isHovered, setIsHovered] = useState(false); // Initialize isHovered state
  const [isHovered2, setIsHovered2] = useState(false); // Initialize isHovered state
  const [isHovered3, setIsHovered3] = useState(false); // Initialize isHovered state
  const [slidesPerView, setSlidesPerView] = useState(2);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1180) {
        setSlidesPerView(3);
        return;
      }
      if (window.innerWidth >= 768) {
        setSlidesPerView(2);
        return;
      }
      if (window.innerWidth < 768) {
        setSlidesPerView(1);
        return;
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Swiper
      centeredSlides={true}
      spaceBetween={5}
      initialSlide={1}
      slidesPerView={slidesPerView}
      className="mySwiper mt-[100px] max-w-[1310px] max-md:mt-16"
    >
      <SwiperSlide>
        <motion.div
          className={`border-devRegDarkBlue shadow-lg border-b-[7px] m-auto w-[385px] h-[250px] overflow-y-hidden ${
            isHovered
              ? `bg-devRegDarkBlue text-white`
              : "bg-white  text-devRegBlack"
          }  relative px-[32px] py-[32px] cursor-pointer`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ transition: "background-color 0.3s, color 0.3s" }}
        >
          <div className="flex flex-col">
            <div className="flex items-center justify-between mb-4">
              <div className="text-2xl font-semibold tracking-tighter">
                Overcoming Distrust
              </div>
              <div>
                <IoIosCheckmarkCircle className="text-3xl text-devRegDarkBlue" />
              </div>
            </div>
            <p
              className={`${
                isHovered ? "text-white" : "text-[#626262] "
              } h-auto`}
            >
              Acknowledging past negative experiences, we implement strict
              vetting and continuous monitoring to ensure integrity, skill, and
              professionalism in our developer community, rebuilding client
              confidence.
            </p>
          </div>
        </motion.div>
      </SwiperSlide>
      <SwiperSlide>
        <motion.div
          className={`border-devRegDarkBlue shadow-lg border-b-[7px] m-auto w-[385px] h-[250px] overflow-y-hidden ${
            isHovered2
              ? `bg-devRegDarkBlue text-white`
              : "bg-white  text-devRegBlack"
          }  relative px-[32px] py-[32px] cursor-pointer`}
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
          style={{ transition: "background-color 0.3s, color 0.3s" }}
        >
          <div className="flex flex-col">
            <div className="flex items-center justify-between mb-4">
              <div className="text-2xl font-semibold tracking-tighter">
                Uniting Across Borders
              </div>
              <div>
                <IoIosCheckmarkCircle className="text-3xl text-devRegDarkBlue" />
              </div>
            </div>
            <p
              className={`${
                isHovered2 ? "text-white" : "text-[#626262] "
              } h-auto`}
            >
              We're dedicated to fostering trust in global partnerships with
              transparency, reliability, and respect, bridging cultural divides
              for successful, collaborative international endeavors.
            </p>
          </div>
        </motion.div>
      </SwiperSlide>
      <SwiperSlide>
        <motion.div
          className={`border-devRegDarkBlue shadow-lg border-b-[7px] m-auto w-[385px] h-[250px] overflow-y-hidden ${
            isHovered3
              ? `bg-devRegDarkBlue text-white`
              : "bg-white  text-devRegBlack"
          }  relative px-[32px] py-[32px] cursor-pointer`}
          onMouseEnter={() => setIsHovered3(true)}
          onMouseLeave={() => setIsHovered3(false)}
          style={{ transition: "background-color 0.3s, color 0.3s" }}
        >
          <div className="flex flex-col">
            <div className="flex items-center justify-between mb-4">
              <div className="text-2xl font-semibold tracking-tighter">
                Scam-Free Guarantee
              </div>
              <div>
                <IoIosCheckmarkCircle className="text-3xl text-devRegDarkBlue" />
              </div>
            </div>
            <p
              className={`${
                isHovered3 ? "text-white" : "text-[#626262] "
              } h-auto`}
            >
              Our rigorous registration process ensures only verified developers
              can participate, drastically reducing scams and building a
              foundation of trust for clients seeking reliable online services.
            </p>
          </div>
        </motion.div>
      </SwiperSlide>
    </Swiper>
  );
}

export default MidHeroCard;
