const items = [
  {
    title: "What is devreg.org?",
    content: (
      <div>
        <p className="mb-4">
          Devreg.org is a platform designed to verify the identities of professionals and individuals across various roles and job types. This platform enables anyone to confirm the identity of a person they are interacting with, whether for professional, personal, or transactional purposes. Our service is especially useful for those seeking to verify the identity of a tradesperson, a professional, or even someone who has met online. By linking profiles on fullidcheck to register.dev, users can send a verification request that prompts the recipient to confirm their identity, report spam, or indicate a stolen ID. This ensures that the person you are dealing with is registered and trusted, providing peace of mind and preventing various types of scams and fraudulent activities, including:
        </p>

        <h3 className="font-bold mt-4 mb-2">Identity Verification Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Identity Theft:</strong> Preventing unauthorized use of personal information to impersonate someone else.</li>
          <li><strong className="text-gray-500 font-semibold">Fake IDs:</strong> Detecting and rejecting forged or counterfeit identification documents.</li>
          <li><strong className="text-gray-500 font-semibold">Phishing Scams:</strong> Protecting against attempts to obtain sensitive information through deceptive emails or websites.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Employment and Freelancer Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Job Offers:</strong> Ensuring job listings are legitimate and employers are verified.</li>
          <li><strong className="text-gray-500 font-semibold">Non-existent Freelancers:</strong> Verifying the identity of remote workers to ensure they are real and qualified.</li>
          <li><strong className="text-gray-500 font-semibold">Fraudulent Resumes:</strong> Checking the authenticity of qualifications and work history.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Financial and Investment Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Ponzi Schemes:</strong> Identifying and preventing fraudulent investment operations.</li>
          <li><strong className="text-gray-500 font-semibold">Advance Fee Fraud:</strong> Ensuring legitimacy before fees for services or opportunities are paid upfront.</li>
          <li><strong className="text-gray-500 font-semibold">Fake Investment Opportunities:</strong> Ensuring legitimacy before fees for services or opportunities are paid upfront.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Online Dating and Romance Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Catfishing:</strong> Preventing individuals from creating fake profiles to deceive others for romantic or financial gain.</li>
          <li><strong className="text-gray-500 font-semibold">Romance Scams:</strong> Verifying identities to ensure genuine interactions on dating sites.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">E-commerce and Online Marketplace Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Sellers:</strong> Ensuring sellers on online marketplaces are legitimate.</li>
          <li><strong className="text-gray-500 font-semibold">Non-delivery of Goods:</strong> Ensuring transactions for goods are legitimate before fees are paid upfront.</li>
          <li><strong className="text-gray-500 font-semibold">Counterfeit Products:</strong> Ensuring the authenticity of products sold online.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Rental and Real Estate Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Rental Listings:</strong> Verifying landlords and property listings to prevent fraudulent rental offers.</li>
          <li><strong className="text-gray-500 font-semibold">Real Estate Fraud:</strong> Ensuring real estate transactions involve legitimate properties and owners.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Financial Services Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Loan Scams:</strong> Verifying lenders to prevent fake loan offers.</li>
          <li><strong className="text-gray-500 font-semibold">Online Lending Scams:</strong> Ensuring online lending platforms are legitimate.</li>
          <li><strong className="text-gray-500 font-semibold">Credit Card Fraud:</strong> Preventing unauthorized use of credit card information.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Healthcare and Insurance Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Medical Identity Theft:</strong> Protecting personal health information from being fraudulently used.</li>
          <li><strong className="text-gray-500 font-semibold">Fake Insurance Policies:</strong> Verifying insurance providers and policies to prevent fraud.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Education and Certification Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Diploma Mills:</strong> Ensuring educational institutions and their credentials are legitimate.</li>
          <li><strong className="text-gray-500 font-semibold">Fake Certifications:</strong> Verifying professional certifications and licenses.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Crowdfunding and Charity Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Charities:</strong> Ensuring charity organizations and fundraising campaigns are legitimate.</li>
          <li><strong className="text-gray-500 font-semibold">Crowdfunding Fraud:</strong> Verifying the authenticity of crowdfunding projects and their creators.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Travel and Accommodation Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Travel Agencies:</strong> Verifying travel agencies and their offers.</li>
          <li><strong className="text-gray-500 font-semibold">Accommodation Scams:</strong> Ensuring listings on accommodation platforms are genuine.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Subscription and Membership Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Subscription Services:</strong> Verifying the legitimacy of subscription-based services.</li>
          <li><strong className="text-gray-500 font-semibold">Membership Scams:</strong> Ensuring membership organizations and their offers are genuine.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Vehicle Rental and Sharing Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Car Rentals:</strong> Verifying vehicle rental companies and their offers.</li>
          <li><strong className="text-gray-500 font-semibold">Car Sharing Fraud:</strong> Ensuring car-sharing services and participants are legitimate.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Second-hand and Used Goods Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Listings:</strong> Verifying sellers and items listed on second-hand marketplaces.</li>
          <li><strong className="text-gray-500 font-semibold">Non-delivery of Second-hand Goods:</strong> Ensuring transactions for used goods are legitimate.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Professional Services Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Fake Professionals:</strong> Verifying the identity and credentials of professionals offering services.</li>
          <li><strong className="text-gray-500 font-semibold">Unlicensed Tradespersons:</strong> Ensuring tradespersons have the necessary licenses and qualifications.</li>
        </ul>

        <h3 className="font-bold mt-4 mb-2">Cybersecurity Scams</h3>
        <ul className="list-disc pl-5">
          <li><strong className="text-gray-500 font-semibold">Hacking and Phishing:</strong> Protecting users from cyber threats and ensuring secure interactions.</li>
          <li><strong className="text-gray-500 font-semibold">Malware and Ransomware:</strong> Preventing the spread of malicious software through verified platforms.</li>
        </ul>
      </div>
    ),
  },
  {
    title: "How do I register on devreg.org?",
    content: (
      <div>
        <ol className="list-decimal pl-5 space-y-2 mb-4">
          <li><strong>Sign Up:</strong> Visit the website and complete the sign-up process</li>
          <li><strong>Pay the Registration Fee:</strong> A one-off registration fee of $195.00 USD is required.</li>
          <li><strong>Facial Recognition:</strong> Upload a photo for facial recognition.</li>
          <li><strong>Upload Government Documents:</strong> Provide government-issued ID documents such as a passport, driver's license, and utility bill for address verification.</li>
          <li><strong>Live Interview:</strong> Complete a 15-minute Zoom interview to verify your identity.</li>
        </ol>

        <p className="mb-4">
          This registration is a one-time process. Every five years, you may update your profile photo for a fee. There is also a voluntary annual membership fee of $135.00 USD. While paying this fee is optional, it provides additional benefits and keeps you as a financial member.
        </p>

        <p className="mb-4">
          Regardless of whether you pay the annual fee, your profile will remain live for the rest of your life.
        </p>

        <p className="mb-4">
          As a financial member, you can participate in the website's badge draw and referral program:
        </p>

        <ul className="list-disc pl-5">
          <li><strong>Badge Draw:</strong> You have a chance to win various badges.</li>
          <li><strong>Referral Program: </strong>Earn $10 for each person you refer who successfully signs up. This payment is made after 90 days to prevent fraudulent sign-ups.</li>
        </ul>
      </div>
    ),
  },
  {
    title: "What is the cost of registration?",
    content: (
      <div>
        <p className="mb-4">The cost for a one-time lifetime registration is $195.00 USD. This fee covers your membership for the first year. After the first year, there is an optional annual membership fee of $135.00 USD.</p>
        <p className="mb-4">Paying the annual fee is optional because we understand that not everyone can afford it. Whether or not you pay the annual fee, your profile will remain active for life. However, paying the fee grants you additional benefits for that year, such as participation in the badge draw and referral program, where you can earn rewards and incentives.</p>
        <p>You are not required to pay the annual fee every year. You can pay occasionally as you can, and it will not be backdated. When you pay the yearly fee, you will be entitled to all services and prizes for that year. The website will remember your payment history, and your profile will remain in good standing regardless of when you choose to pay.</p>
      </div>
    )
  },
  {
    title: "What happens if I don't pay the annual membership fee?",
    content: (
      <div>
        <p className="mb-4">
          If you choose not to pay the annual membership fee, your profile will remain active and visible to clients. However, there are several benefits you will miss out on if you are not a financial member:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Participation in Badge Draws:</strong> Access to win various badges and recognitions is not available if you are unfinancial.
          </li>
          <li>
            <strong>Priority Listing:</strong> Your business profile will be unlinked, giving you less visibility and decreasing your chances of client contact.
          </li>
          <li>
            <strong>Access to Premium Features:</strong> You will not have access to additional tools and features that could help you manage and promote your services more effectively.
          </li>
        </ul>
        <p className="mt-4">
          While your profile will remain visible without the annual fee, these benefits are designed to encourage you to stay an active financial member, maximizing your opportunities and engagement on the platform. The yearly fee ensures full leverage of all the services and protections devreg.org offers. If you do not pay your expenses, the links on your profile will be removed, reducing your chances of client contact.
        </p>
      </div>
    )
  },
  {
    title: "What documents do I need to provide during registration?",
    content: (
      <div>
        <p className="mb-4">
          During registration, you need to provide the following documents:
        </p>
        <ol className="list-decimal pl-5 space-y-2">
          <li>
            <strong>Government-Issued ID:</strong> Includes documents such as a passport or driver's license.
          </li>
          <li>
            <strong>Proof of Address:</strong> A utility bill or similar document to verify your address.
          </li>
          <li>
            <strong>Work Licenses:</strong> Any professional or trade license relevant to your work.
          </li>
          <li>
            <strong>Additional Identification Documents:</strong> Any other government-issued identification that may be required for verification.
          </li>
        </ol>
        <p className="mt-4">
          These documents must be uploaded to the platform and may also be requested during the 15-minute live Zoom interview to verify your identity further. This thorough verification process helps ensure the legitimacy and trustworthiness of all members on devreg.org.
        </p>
      </div>
    ),
  },
  {
    title: "How often do I need to update my profile photo?",
    content: (
      <div>
        <p className="mb-4">
          You must update your profile photo every five years to maintain your verified status.
        </p>

        <p>
          For members aged 68 years and older, we offer discounted rates for updating your profile photo. Please approach the admin for more details on how to apply for this discount.
        </p>
      </div>
    ),
  },
  {
    title: "How do clients verify my identity?",
    content: (
      <div>
        <p className="mb-4">
          Clients can verify your identity through devreg.org by searching for registered developers. When clients search for you on our platform, they can see that you have undergone a thorough verification process, including submission of government-issued IDs, facial recognition, and a live interview. This ensures that they are dealing with verified and trustworthy individuals.
        </p>

        <p>
          Additionally, clients can send a verification request through devreg.org, which prompts you to confirm your identity. This process helps to provide an extra layer of security and trust, ensuring that interactions and transactions are safe and legitimate
        </p>
      </div>
    ),
  },
  {
    title: "How does the referral program work?",
    content: (
      <div>
        <p>
          The referral program allows you to earn $10 for each person you refer who successfully signs up. This payment is made after 90 days to prevent fraudulent sign-ups. To participate, you must be a financial member with an active annual membership.
        </p>
      </div>
    ),
  },
  {
    title: "What is the badge draw, and how can I participate?",
    content: (
      <div>
        <p className="mb-4">
          The badge draw is a feature that offers financial members a chance to win cash prizes. Each registered person is linked to a unique serial number, displayed as a badge showing that they are a registered and verified member.
        </p>

        <p>
          A badge draw is held every week, and a serial number is randomly selected from all members across our platforms. The prize amount grows as members increase, and the cash prize is instantly paid to the winning member's account. You must have paid your annual membership fee to participate in the badge draw. Winning the badge draw can also enhance your profile visibility and credibility on the platform.
        </p>
      </div>
    ),
  },
  {
    title: "How is my personal information protected?",
    content: (
      <div>
        <p className="mb-4">
          devreg.org takes your privacy and security seriously. We use advanced encryption and security measures to protect your personal information. Your data is only used for verification and not shared with third parties.
        </p>

        <p className="mb-4">
          The files you upload during registration are deleted from the website within 2 hours of signing up. The only information visible on the site is your first and last name, though you may choose to add a middle name to your profile. All other information you choose to include is at your discretion.
        </p>

        <p>
          After uploading and being checked by a manager, your documents are zipped, password protected, given a serial number as a document ID, and then downloaded from the server to an offline server for secure storage. Once this process is complete, the files on the server are wholly deleted. This ensures that your sensitive documents are protected and not accessible online. They will never come near to being online.
        </p>
      </div>
    ),
  },
  {
    title: "Can I update my information after registration?",
    content: (
      <div>
        <p className="mb-4">
          Yes, you can update your profile information, including your photo and contact details, through your account settings. However, there are some specific guidelines and checks for significant updates:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Profile Photo:</strong> When you update your photo, a warning will be sent to the admin. You must use an accurate, clear picture and not an avatar. This ensures the authenticity of your profile.
          </li>
        </ul>
        <p className="mt-4">
          We keep a watchful eye on all changes to maintain the integrity and trustworthiness of our platform.
        </p>
      </div>
    ),
  },
  {
    title: "What should I do if I encounter a problem during registration?",
    content: (
      <div>
        <p>
          Contact our support team through the website's Help Desk section if you encounter any issues during registration. We are here to assist you and ensure a smooth registration experience.
        </p>
      </div>
    ),
  },
  {
    title: "How long does the verification process take?",
    content: (
      <div>
        <p className="mb-4">
          The verification process, including the live interview, is designed to be immediate. We prefer the meeting on the same day as your registration to ensure that your files are not stored on the server longer than necessary.
        </p>

        <p>
          You can select the time and date for your Zoom interview during registration. If you download the Zoom meeting application and have a video conference setup, you can choose a time that suits you best, ensuring a smooth and efficient verification process.
        </p>
      </div>
    ),
  },
  {
    title: "What happens if a complaint is lodged against me?",
    content: (
      <div>
        <p className="mb-4">
          If a complaint is lodged against you, our team will review the complaint and the associated evidence. You will be contacted and asked to explain if the complaint is related to scamming. If you refuse to respond or allow contact, a complaint will be added to your profile.
        </p>

        <p>
          Please remember that once a scamming-related complaint is logged into your profile, it will remain permanently. It is in your best interest to respond to the complaint promptly to defend yourself and provide your side of the story. We focus on anti-scam activities and take all scam-related complaints seriously to maintain a trustworthy community. Complaints unrelated to scams are not our primary concern.
        </p>
      </div>
    ),
  },
  {
    title: 'Can I deactivate or delete my account?',
    content: (
      <div>
        <p>
          No, you cannot delete your account but can deactivate it anytime through your account settings. If you deactivate your account, your profile will no longer be visible to clients. However, your account and data will remain in our system. You can update your profile status to indicate that you are retired or unavailable, such as "I am retired and gone fishing," but your profile will remain part of the platform.
        </p>
      </div>
    ),
  },
  {
    title: "What types of jobs and professions can be verified on devreg.org?",
    content: (
      <div>
        <p className="mb-4">
          devreg.org can verify a wide range of developers jobs
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Verification Process: </strong>During the verification process, individuals are requested to show their credentials during the interview and are encouraged to display them on their profile. Our platform ensures that individuals across various job types are legitimate and trustworthy.
          </li>
          <li>
            <strong>Complaint Handling: </strong>If a person complains that someone is not what they claim to be, a "Beware" warning is added to their profile after the complaint is reviewed and found valid. This helps maintain the integrity and trustworthiness of our community.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'How does devreg.org ensure the authenticity of the verification process?',
    content: (
      <div>
        <p className="mb-4">
          devreg.org uses a multi-step verification process to ensure the authenticity and legitimacy of all members on our platform. This process includes:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Document Submission:</strong> Members must submit government-issued IDs, proof of address, and relevant work licenses or certifications.
          </li>
          <li>
            <strong>Facial Recognition:</strong> Members upload a photo for facial recognition to confirm their identity.
          </li>
          <li>
            <strong>Live Zoom Interview:</strong> Members participate in a live Zoom interview with a manager to verify their identity and credentials further. During this interview, they are requested to show their documents and credentials.
          </li>
        </ul>
        <p className="mt-4">
          This thorough verification process helps us maintain a trustworthy and reliable community.
        </p>
      </div>
    ),
  },
  {
    title: 'Is my information shared with other members or third parties?',
    content: (
      <div>
        <p>
          Your information is not shared with other members or third parties. Your data is used solely for verification and is protected with advanced encryption and security measures. We prioritise your privacy and ensure your sensitive information remains secure and confidential.
        </p>
      </div>
    ),
  },
  {
    title: 'What if I lose access to my account?',
    content: (
      <div>
        <p>
          If you lose access to your account, you can reset your password using the "Forgot Password" feature on the login page. If you continue to experience issues, please contact our support team through the Help Desk section for further assistance. To ensure your security, you may be asked to go through a Zoom meeting to confirm your identity. Our team is here to help you regain access to your account as quickly and securely as possible.
        </p>
      </div>
    ),
  },
  {
    title: 'How do I participate in the referral program?',
    content: (
      <div>
        <p>
          You do not need to be a financial member to participate in the referral program. You can refer new members through your account, and you will earn $10 for each person who successfully signs up. This payment is made after 90 days to prevent fraudulent sign-ups. Additionally, the referral program helps you build your credit account profile, which can be used to pay for goods and services on the platform. The referral program is a great way to earn rewards while helping to expand our trusted community.
        </p>
      </div>
    ),
  },
  {
    title: 'What should I do if I suspect someone on the platform is a scammer?',
    content: (
      <div>
        <p className="mb-4">
          If you suspect someone on the platform is a scammer, please report them immediately through the report feature on their profile. All reports are sent directly to the admin, who will investigate the complaint and take appropriate action to ensure the integrity of our community. We take all reports seriously and work diligently to maintain a safe and trustworthy environment for all members.
        </p>
        <p>
          Please note that if a report is found to be falsely made, the person sending the complaint will be labelled a nuisance, which may affect their standing within the community.
        </p>
      </div>
    ),
  },
  {
    title: 'Can I upgrade my membership after registration?',
    content: (
      <div>
        <p>
          Currently, we do not offer an upgrade option for memberships. However, we are considering this feature for the future. Stay tuned for updates as we continue to improve and expand our services.
        </p>
      </div>
    ),
  },
  {
    title: 'How can I ensure my profile stands out to clients?',
    content: (
      <div>
        <p className="mb-4">
          To ensure your profile stands out to clients, follow these tips:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Keep Information Up to Date:</strong> Regularly update your profile information, including your profile photo and work licenses.
          </li>
          <li>
            <strong>Complete Your Profile:</strong> Ensure all sections of your profile are filled out with accurate and detailed information.
          </li>
          <li>
            <strong>Add Credentials:</strong> Display your credentials and certifications to build trust and showcase your qualifications.
          </li>
          <li>
            <strong>Annual Membership:</strong> Participate in the annual membership to gain access to premium features and prioritised listing, which can enhance your profile visibility.
          </li>
          <li>
            <strong>Engage with Clients:</strong> Respond promptly to client inquiries and maintain a professional demeanour in all communications.
          </li>
          <li>
            <strong>Use Keywords:</strong> Use relevant keywords in your profile description to improve searchability. For example, if you are a Front-End Developer, mention terms like "Front-End Developer," "frontend developer," and "Developers Frontend."
          </li>
          <li>
            <strong>Name Your Photos Appropriately:</strong> Name them with relevant keywords when uploading JPEG photos. For instance, name the photo "Experienced Front-End Developer." However, avoid repeating the same keyword multiple times to prevent reducing search engine effectiveness.
          </li>
        </ul>
        <p className="mt-4">
          Maintaining a complete and up-to-date profile and strategically using varied keywords can increase your chances of attracting clients and standing out on the platform.
        </p>
      </div>
    ),
  },
  {
    title: 'Are there any restrictions on who can join devreg.org?',
    content: (
      <div>
        <p>
          devreg.org is open to all software developers, regardless of profession or background. Our goal is to verify the identities of individuals across various fields, to ensure trust and authenticity. By confirming your identity on devreg.org, you can assure others that you are who you say you are, fostering a trustworthy community. All members must undergo a thorough verification process and provide the necessary documents, such as government-issued IDs, proof of address, and relevant work licenses, to ensure the integrity and trustworthiness of our platform.
        </p>
      </div>
    ),
  },
  {
    title: 'How does devreg.org handle disputes between clients and members?',
    content: (
      <div>
        <p>
          devreg.org encourages clients and members to resolve disputes amicably. Members can contact our support team for assistance if a resolution cannot be reached. We will review the case, investigate the details, and guide to help resolve the issue. If we are unable to help, we will recommend other services that may be able to assist you. Our goal is to ensure a fair and trustworthy environment for all users on the platform.
        </p>
      </div>
    ),
  },
  {
    title: 'Why do you ask for next of kin information?',
    content: (
      <div>
        <p className="mb-4">
          We ask for next of kin information as an additional security measure. This information helps us to:
        </p>
        <ol className="list-decimal pl-5 space-y-2">
          <li>
            <strong>Emergency Contact:</strong> Have a reliable contact in case of emergencies or unforeseen situations involving your account.
          </li>
          <li>
            <strong>Verification Purposes:</strong> Provide an extra layer of verification to ensure the accuracy and legitimacy of your identity.
          </li>
          <li>
            <strong>Account Recovery:</strong> Assist in account recovery processes if you cannot access your account and require support.
          </li>
          <li>
            <strong>Fund Transfer:</strong> Allow your funds to be transferred to your next of kin in the event of your passing.
          </li>
        </ol>
        <p className="mt-4">
          Your next of kin information is securely stored and is only used for these specific purposes. It is not shared with third parties or other members. Providing this information helps us maintain a safe and trustworthy platform for all users.
        </p>
      </div>
    ),
  },
  {
    title: 'How do I contact customer support?',
    content: (
      <div>
        <p>
          You can contact our customer support team through the Help Desk section on our website. We're available to help you with any questions or issues regarding your account or the verification process. If you cannot find what you need, you may also contact the admin directly using your profile and message the admin for further assistance.
        </p>
      </div>
    ),
  },
  {
    title: 'What if my verification is unsuccessful?',
    content: (
      <div>
        <p>
          If your verification is unsuccessful, you will receive an email detailing why the verification failed and the steps you can take to fix any issues. Your case will be escalated to tier one, which is the admin. The admin will make contact with you and, depending on your circumstances, will work with you to resolve the issue and complete your verification.
        </p>
      </div>
    ),
  },
  {
    title: 'Is devreg.org worldwide?',
    content: (
      <div>
        <p>
          Yes, devreg.org is available to individuals worldwide. Our platform is designed to verify identities globally, ensuring trust and authenticity across various regions. We aim to provide a reliable and secure verification service for users worldwide.
        </p>
      </div>
    ),
  },
  {
    title: 'How can I provide feedback about my experience with devreg.org?',
    content: (
      <div>
        <p className="mb-4">
          We value your feedback and constantly seek to improve our services. While we do not have a feedback form, if you like our service, please recommend us to others and earn a reward for your support. If you are unsatisfied with our service, don't hesitate to contact our customer support team to share your concerns; we will work to improve it.
        </p>
        <p>
          We also reward users who provide constructive support by identifying faults in our efforts to help others. Your input helps us enhance our platform and provide a better experience for all users.
        </p>
      </div>
    ),
  },
  {
    title: 'What are the benefits of being a verified member on devreg.org?',
    content: (
      <div>
        <p className="mb-4">
          Being a verified member on devreg.org provides numerous benefits, including:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Enhanced Trust and Credibility:</strong> Gain trust and credibility with clients by having a verified profile.
          </li>
          <li>
            <strong>Increased Visibility:</strong> Enjoy higher visibility and profile ranking on the platform.
          </li>
          <li>
            <strong>Access to Premium Features:</strong> Participate in badge draws, the referral program, and other premium features.
          </li>
          <li>
            <strong>Showcase Legitimacy:</strong> Demonstrate your legitimacy and qualifications through a verified profile, making it easier for clients to choose you.
          </li>
          <li>
            <strong>Trust Beyond Your Trade:</strong> Allow others, even outside your trade, to verify you are who you are, enhancing trust in various interactions.
          </li>
        </ul>
        <p className="mt-4">
          These benefits help you stand out and maximise your opportunities on devreg.org.
        </p>
      </div>
    ),
  },
  {
    title: 'How do I update my next of kin information?',
    content: (
      <div>
        <p>
          To update your next of kin information, you must do it through the admin message panel. It is essential to keep this information current to ensure your account is managed correctly in case of emergencies or other unforeseen events. Keeping your next of kin information current helps us provide the best support and ensures your account is handled according to your wishes. Note: The next of kin information is not stored on the server.
        </p>
      </div>
    ),
  },
  {
    title: 'Are there any fees for updating my information?',
    content: (
      <div>
        <p>
          Updating your basic profile information, such as contact details and work licenses, is free. However, there may be a fee for updating your profile photo or other specific services. If you get married or have a change of name, there is a fee of $135.00 to reconstruct your information. Don't hesitate to contact our support team for details on specific fees. Keeping your profile information up to date is essential for maintaining the accuracy and trustworthiness of your profile.
        </p>
      </div>
    ),
  },
  {
    title: 'How do I know if my documents have been securely processed?',
    content: (
      <div>
        <p>
          Once your documents are uploaded, they are immediately checked by a manager, zipped, password protected, given a serial number, and downloaded to an offline server for secure storage. The files on the server are then wholly deleted. You will receive a confirmation once your documents have been securely processed.
        </p>
      </div>
    ),
  },
  {
    title: 'What measures does devreg.org take to prevent fraud?',
    content: (
      <div>
        <p className="mb-4">
          devreg.org employs several measures to prevent fraud and ensure the integrity of our platform:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Advanced Encryption:</strong> We use advanced encryption techniques to protect your data.
          </li>
          <li>
            <strong>Multi-Step Verification:</strong> Our verification process includes document submission, facial recognition, and a live Zoom interview.
          </li>
          <li>
            <strong>Thorough Document Checks:</strong> Our team thoroughly checks all documents to ensure their authenticity.
          </li>
          <li>
            <strong>Monitoring Profile Updates:</strong> We monitor profile updates to detect suspicious changes.
          </li>
          <li>
            <strong>Handling Complaints:</strong> We investigate all complaints to ensure all members are legitimate and trustworthy.
          </li>
        </ul>
        <p className="mt-4">
          Any fraudulent activity is taken seriously and dealt with promptly to maintain a safe and trustworthy user environment.
        </p>
      </div>
    ),
  },
  {
    title: 'What should I expect during the interview?',
    content: (
      <div>
        <p className="mb-4">
          The interview process is an essential part of our verification to ensure the legitimacy of all members. Here’s what you can expect:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Duration:</strong> The interview typically takes around 15 minutes.
          </li>
          <li>
            <strong>Recording:</strong> The interview is recorded to capture your facial features and to ensure that all provided documents are genuine.
          </li>
          <li>
            <strong>Document Verification:</strong> You will be asked to show your government-issued ID, proof of address, work licenses, and other relevant documents.
          </li>
          <li>
            <strong>Qualification Discussion:</strong> The interviewer will ask about your qualifications and work experience to verify the information you provided.
          </li>
          <li>
            <strong>Profile Update:</strong> All verified information, including the recorded interview and document verification, is zipped to a file and named with your serial number. This file is then sent to the admin for download and deletion from the server. You will be responsible for accurately updating your profile with this verified information.
          </li>
        </ul>
        <p className="mt-4">
          This thorough interview process helps maintain the trust and authenticity of our platform by ensuring that all members are who they claim to be.
        </p>
      </div>
    ),
  },
  {
    title: 'What if law enforcement wants access to the server for information on the members?',
    content: (
      <div>
        <p>
          We take the privacy and security of our members very seriously. Law enforcement access to our server for member information will never happen. We are committed to protecting your data and would go to great lengths to ensure its confidentiality. Additionally, our server is in a secure and undisclosed area, making it difficult to be found. Your privacy and trust are our top priorities.
        </p>
      </div>
    ),
  },
  {
    title: 'Can I use devreg.org for personal identity verification?',
    content: (
      <div>
        <p>
          Yes, devreg.org can be used for personal identity verification. Whether you are a professional developer or an individual looking to verify your identity for personal reasons, our platform provides a thorough and secure verification process. If you are verified on our platform, companies and individuals can be confident that you are who you say you are, ensuring trust and authenticity in various interactions and transactions.
        </p>
      </div>
    ),
  },
  {
    title: 'What should I do if I forget my login credentials?',
    content: (
      <div>
        <p className="mb-4">
          If you forget your login credentials, you can use the "Forgot Password" feature on the login page to reset your password. If you continue to experience issues, please contact our support team for further assistance.
        </p>
        <p>
          If you lose all your login details and your phone, you can contact the Help Desk. You must supply all your relevant documentation again during a Zoom meeting to verify your identity and regain access to your account.
        </p>
      </div>
    ),
  },
  {
    title: 'How can I ensure my profile is not flagged for inactivity?',
    content: (
      <div>
        <p>
          Your profile will not be flagged for inactivity even if you do not access it yearly. However, it is recommended to log in regularly to keep your information current. You must update your profile photo every five years, but your profile will remain active and visible even if you access it infrequently. Maintaining up-to-date information helps ensure the accuracy and trustworthiness of your profile.
        </p>
      </div>
    ),
  },
  {
    title: 'Are there any guidelines for the profile photo I upload?',
    content: (
      <div>
        <p>
          Yes, your profile photo should be a clear, recent image of yourself. Avatars, cartoons, or heavily edited photos are not allowed. The photo should clearly show your face to help verify your identity. This photo is used in our facial recognition system, which is cross-checked with your passport photo and checked by admin to ensure accuracy. For adequate verification, the photo you upload must accurately represent yourself.
        </p>
      </div>
    ),
  },
  {
    title: 'What happens if I provide false information during registration?',
    content: (
      <div>
        <p className="mb-4">
          Providing false information during registration is strictly prohibited. When you upload your documents and go through facial recognition, followed by the interview, we will have all your information on the website. This includes your photo, and you will already have an ID number.
        </p>
        <p className="mb-4">
          If you attempt to use false documents, your profile will be automatically flagged as fraudulent. This status will remain permanently, along with your photo, and will never be deleted. If you attempt to register again, the facial recognition system will identify you as a fraudster, and any new documents you use will be added to your existing file, further marking you as a fraud.
        </p>
        <p>
          It is highly recommended that you never attempt to defraud this platform. We take fraud very seriously and are incredibly unforgiving. We prioritize the integrity and security of our community over privacy laws when it comes to fraudulent activities.
        </p>
      </div>
    ),
  },
  {
    title: 'Can I refer friends or family to join devreg.org?',
    content: (
      <div>
        <p>
          Yes, you can refer friends or family to join devreg.org. You can earn a referral reward for each person who successfully signs up through your referral link. This is a great way to help grow our community while earning rewards for yourself. Make sure they use your referral link during the registration process to ensure you receive credit for the referral.
        </p>
      </div>
    ),
  },
  {
    title: 'How do I participate in community discussions or forums on devreg.org?',
    content: (
      <div>
        <p>
          To participate in community discussions on forums on devreg.org, log in to your account and navigate to the forum section. The forum is the only place to discuss anything on our platform. Here, you can join discussions, ask questions, share experiences, and connect with other members. It is a great place to engage with the community, gain insights, and contribute to discussions relevant to your interests and professional field.
        </p>
      </div>
    ),
  },
  {
    title: 'What if I encounter a technical issue on the website?',
    content: (
      <div>
        <p>
          If you encounter a technical issue on the website, please contact our support team through the Help Desk section. We will work to resolve the issue as quickly as possible to ensure a smooth user experience. Our team provides prompt assistance and ensures that technical problems are addressed efficiently.
        </p>
      </div>
    ),
  },
  {
    title: 'Can I suggest new features or improvements for devreg.org?',
    content: (
      <div>
        <p className="mb-4">
          Absolutely! We value your input and continually seek ways to improve our platform. If you have a great suggestion, such as an app that helps others stop more scams by working in a specific way, we would love to hear from you. Please submit your suggestions through the Help Desk section or contact our support team directly.
        </p>
        <p>
          We will provide a financial reward to anyone whose suggestions we implement. Your feedback is essential in helping us enhance our services and better serve our community.
        </p>
      </div>
    ),
  },
];

export { items };
