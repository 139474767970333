import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../actions";
import {
    verifyAccessKey
} from "../../../api/apiCall.js";
import BacktoLogin from "../components/BackToLogin";
import { FaPaste } from "react-icons/fa6";

function EnterRecoveryCodes() {
    const token = localStorage.getItem('token');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [code, setCode] = useState("");

    const handleInputChange = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, "");
        setCode(value);
    };

    const handleVerifyAccessKey = async () => {
        dispatch(setIsLoading(true));
        try{
            let data = {
                token: token,
                payloads: {
                    access_key: code
                }
            }

            const response = await verifyAccessKey(data);
            if(response.status === 200){
                navigate("/upload-documentsreq");
            }
            else{
                alert("Invalid access key. Please try again.");
            }
        }
        catch(error){
            console.log("Error verifying your access key: ", error);
            setError(error?.response?.data?.message);
        }
        finally{
            dispatch(setIsLoading(false));
        }
    }
    const handlePaste = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            setCode(clipboardText);
        } catch (error) {
            console.error("Failed to read clipboard contents: ", error);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-devRegDarkBlue">
            <div className="flex flex-col p-6 mt-6 max-w-full bg-white rounded-3xl shadow-sm w-[400px] max-md:px-8">
                <div className="flex items-center justify-between w-full mb-4">
                    <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
                        <BacktoLogin />
                    </div>
                    <img
                        src="/logoFooter.png"
                        alt="Devreg Logo"
                        className="h-8"
                    />
                </div>
                <hr className="w-full border-t border-gray-200" />
                <div className="mt-6 text-xl text-center font-semibold text-blue-900 max-sm:mr-auto text-blue-900">
                    ACCESS KEY
                </div>
                <div className="mt-4 text-center text-base text-zinc-700 text-opacity-80 max-sm:text-sm max-md:text-md">
                    To continue with your registration, please enter the 6-character code that we sent to your email
                </div>
                <div className="mt-6 relative">
                    <div className="text-center">
                        <small className="text-red-600 w-full text-center">{error}</small>
                    </div>
                    <input
                        type="text"
                        className="w-full px-3 py-2 rounded-md border border-solid border-zinc-700 border-opacity-20 h-[46px] pr-[70px]"
                        value={code}
                        onChange={handleInputChange}
                        placeholder="Access Key"
                        maxLength="6"
                    />
                    <button
                        onClick={handlePaste}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-200 text-white p-2 rounded-md"
                    >
                        <FaPaste className="text-gray-400"/>
                    </button>
                </div>
                <div className="flex justify-center items-center mt-2">
                    <div
                        className="cursor-pointer w-2/5 px-9 py-2 mt-4 text-base font-semibold text-blue-900 whitespace-nowrap rounded-3xl max-md:px-5 text-center bg-blue-100"
                    >
                        Cancel
                    </div>
                    <button
                        className="cursor-pointer w-3/5 px-9 py-2 mt-4 text-base font-semibold text-white whitespace-nowrap rounded-3xl max-md:px-5 text-center bg-blue-900 ml-4"
                        onClick={handleVerifyAccessKey}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EnterRecoveryCodes;
