import React from "react";
const data = [
  {
    img: "/offer1.png",
    title: "Access To CryptoCurrency Registration",
    description:
      "Once registered, you gain access to manage any CryptoCurrency coin anonymously if preferred. Verify your details at https://coinreg.info/ by entering your coin's name. We advise providing extensive public information about your coin. Membership at Devreg.org allows developers to create and advertise profiles without linking to specific cryptocurrencies. For further details, visit the Questions and Answers section.",
  },
  {
    img: "/offer3.png",
    title: "Developers Discounted Tools ",
    description:
      "Once we see an influx of developers joining our community, we aim to negotiate with companies for discounted rates on tools. These savings will be directly passed on to Devreg® registered members. It's crucial to maintain your membership by paying your annual fees; without this, access to these tools will not be granted. If there are specific tools you require, or if you believe we could secure better discounts, we encourage you to inform us. We're committed to making this website an increasingly valuable resource for you all.",
  },
  {
    img: "/weStrive3.png",
    title: "Once Registered",
    description:
      "Join our vibrant community and get a chance to win big in our thrilling monthly badge draw! Every member is automatically entered, offering an opportunity to win a substantial jackpot that accumulates monthly until a lucky member claims it. Plus, for every new developer you refer, you'll earn a $10. It's not just about winning; it's about being part of an innovative and supportive environment where your ideas for devreg.org, coinreg.info, and register.dev could be recognized and rewarded. Don’t miss this exciting opportunity to connect, create, and potentially win big!",
  },
];
function Section1() {
  return (
    <div className="max-w-main m-auto px-10 max-md:px-3">
      <div className="font-bold max-md:text-sm20  max-md:text-center text-[30px] text-devRegDarkBlue mt-14">
        We Strive To Make Our Members Happy
      </div>

      <div className="flex flex-col justify-center items-center px-1 mt-4 gap-6">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex gap-1 flex-col max-md:justify-center items-start max-md:items-center max-md:px-10 min-[768px]:max-w-full bg-white rounded-lg p-6 border border-[#E5E7EB]"
          >
            <img src={item.img} className="w-auto h-[60px] " alt={item.title} />
            <div className="font-semibold max-md:text-sm18 text-[24px] text-devRegBlack mt-3">
              {item.title}
            </div>
            <div className="max-sm:text-sm14 text-black70">
              {item.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Section1;