import React from "react";
import TopBanner from "../components/TopBanner";
import WhyRegisterSwiper from "./WhyRegisterSwiper";
import BecomeRecognized from "../components/BecomeRecognized";
import WhatWeOffer from "./WhatWeOffer";
import CardWhy from "./CardWhy";

function WhyRegister() {
  return (
      <div className="bg-[#F9FAFB]">
        <TopBanner imgBg={"/WhyRegisterBG.png"} center={false} class="z-1"/>
        
        <div className="max-w-main mt-8 m-auto px-10 max-md:px-3">
          <button
            disabled={true}
            className=" py-1.5 px-6 mt-8 text-base font-medium text-devRegBlue uppercase bg-cyan-50 rounded-full max-md:mt-10 max-md:ml-2.5 max-sm:text-sm16"
          >
            Why Choose us?
          </button>
          <div className="flex max-md:flex-col justify-between">
            <div className="pr-3 max-md:px-3 w-2/5 text-[30px] max-md:w-full font-bold leading-9 max-md:leading-7 max-md:text-sm20 text-devRegDarkBlue mt-4">
              At DevReg, we want to ensure that software developers are proven,
              traceable, and confirmed.
            </div>
            <p className="w-1/2 max-md:w-full  px-3 text-sm15 mt-8">
              They have submitted the necessary legal documents, have been vetted
              through a live interview and can be identified using a facial
              recognition system. All registered software development
              professionals at DevReg can prove “THEY ARE WHO THEY SAY THEY ARE”.
            </p>
          </div>
        </div>
        <div className="max-w-main m-auto px-10 max-md:px-3">
          <div className="font-bold max-md:text-sm20 text-[30px] text-devRegDarkBlue mt-20 max-md:px-3">
            Why Register at DevReg?
          </div>

          <div className="min-[1210px]:hidden mt-12 max-md:px-3">
            <WhyRegisterSwiper />
          </div>
          <div className="max-[1210px]:hidden mt-12 max-md:px-3">
            <CardWhy />
          </div>
        </div>
        <BecomeRecognized />
        <div className="mt-12 max-md:px-3">
          <WhatWeOffer />
        </div>
      </div>
  );
}

export default WhyRegister;