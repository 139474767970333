import * as React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();

    React.useEffect(() => {
        // Define the initialization function and attach it to the window object
        window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: 'en',
                    layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
                },
                'google_translate_element'
            );

            // Wait a moment to ensure elements have loaded, then remove unwanted parts
            setTimeout(() => {
                // Remove "Isalin" and "Powered by Google" text
                const branding = document.querySelector('.goog-logo-link');
                const extraText = document.querySelector('.goog-te-gadget > div > div');

                if (branding) branding.style.display = 'none';
                if (extraText) extraText.style.display = 'none';
            }, 1000); // Adjust the timeout if needed for slower loads
        };

        // Load the Google Translate script if it's not already loaded
        const loadGoogleTranslateScript = () => {
            if (!window.google || !window.google.translate) {
                const script = document.createElement('script');
                script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
                script.async = true;
                document.body.appendChild(script);
            } else {
                window.googleTranslateElementInit();
            }
        };

        loadGoogleTranslateScript();
    }, []);

    const handleClick = (to) => {
        navigate(to);
        window.scrollTo(0, 0);
    };
    return (
        <>
            <style>
                {
                    `
                    #google_translate_element .goog-te-gadget-simple {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }

                    .goog-logo-link,
                    .goog-te-gadget span,
                    .goog-te-gadget .goog-te-banner-frame,
                    .goog-te-gadget > div > div:last-child {
                        display: none !important;
                    }
                        .goog-te-gadget {
                        font-size: 0px;
                    }
                        
                    body {
                        top: 0px !important;
                    }

                    body > .skiptranslate {
                        display: none;
                    }
                `
                }
            </style>
            <div className="flex overflow-hidden flex-col items-center px-32 bg-white border-t border-neutral-300 max-md:px-5">
                <div className="flex overflow-hidden flex-col py-8 w-[1366px] max-md:w-full">
                    <div className="flex flex-col items-center w-full max-md:max-w-full">
                        <div className="flex flex-col max-w-full w-[200px] cursor-pointer">
                            <img
                                loading="lazy"
                                src="/logoFooter.png"
                                className="object-contain w-full aspect-[3.57]"
                                onClick={() => {
                                    navigate("/");
                                    window.scrollTo(0, 0);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center mt-8 w-full text-sm text-gray-500 max-md:max-w-full">
                        <div className="flex flex-wrap gap-6 justify-center items-end w-full max-md:max-w-full">
                            <div onClick={() => handleClick('/why-register')} className="cursor-pointer">Why DevReg?</div>
                            <div onClick={() => handleClick('/benefits')} className="cursor-pointer">Benefit Page</div>
                            <div onClick={() => handleClick('/about-profile')} className="cursor-pointer">About Profile</div>
                            <div onClick={() => handleClick('/requirements')} className="cursor-pointer">Requirements</div>
                            <div onClick={() => handleClick('/about-us')} className="cursor-pointer">About Us</div>
                            <div onClick={() => handleClick('/faq')} className="cursor-pointer">FAQ</div>
                            <div onClick={() => handleClick('/contact')} className="cursor-pointer">Contact Us</div>
                        </div>
                    </div>
                    <div className="flex flex-col mt-8 w-full max-md:max-w-full">
                        <div className="flex items-center justify-between max-[1200px]:self-center max-sm:self-center max-sm:ml-px max-md:flex-col max-md:gap-6">
                            <div className="flex gap-6">
                                <img
                                    alt=""
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/1901a358f8092a381235ece0fb0d95a6570db23fc758bacf00c8c16d0d302f41?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                                    className="object-contain shrink-0 w-5 aspect-square"
                                />
                                <img
                                    alt=""
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a1eb0b62d062c5ca82111e2c3cf19789c9938cdde87b67b0714a59717bdcc3f?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                                    className="object-contain shrink-0 w-5 aspect-square"
                                />
                                <img
                                    alt=""
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/83c7ffd13139d99fa3e0d76847d2c5c8da55691f77b93fc0682abe6befe7b92e?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                                    className="object-contain shrink-0 w-5 aspect-square"
                                />
                                <img
                                    alt=""
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/b293382a34a136575cce54197a69c55ca982775569524959f75a64194c9086bb?placeholderIfAbsent=true&apiKey=330fc829dca74d1b9d79524f3edd258c"
                                    className="object-contain shrink-0 w-5 aspect-square"
                                />
                            </div>
                            <div className="flex flex-col items-start text-start max-md:text-center max-md:items-center">
                                <p className="text-sm text-gray-500 flex items-center">
                                    <span>
                                        Change System Language
                                    </span>
                                </p>
                                <button id="google_translate_element"></button>
                            </div>
                        </div>
                        <div className="flex flex-wrap gap-10 justify-between items-center pt-4 mt-4 w-full text-sm text-gray-500 border-t border-solid min-h-[52px] max-md:max-w-full">
                            <div className="flex gap-4 items-center self-stretch my-auto min-w-[240px] max-md:mx-auto max-md:justify-center">
                                <div onClick={() => handleClick('/terms-and-conditions')} className="self-stretch my-auto max-sm:mx-auto max-sm:text-center cursor-pointer">
                                    Terms of Service
                                </div>
                                <div onClick={() => handleClick('/anti-bribery-policy')} className="self-stretch my-auto max-sm:mx-auto max-sm:text-center cursor-pointer">
                                    Anti-Bribery & Corruption
                                </div>
                                <div onClick={() => handleClick('/privacy')} className="self-stretch my-auto max-sm:mx-auto max-sm:text-center cursor-pointer">
                                    Privacy Policy
                                </div>
                            </div>
                            <div className="gap-2 my-auto min-w-[240px] w-[436px] self-stretch max-md:max-w-full text-end max-lg:text-start  max-md:text-center">
                                © 2024 Devreg. All Rights Reserved
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;