
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  registerCoin,
} from "../../../api/apiCall.js";


function RegisterCoinMain() {
  const [isAnonymous, setIsAnonymous] = useState(0);
  const [coinType, setCoinType] = useState("Type-1");
  const [coinName, setCoinName] = useState("");
  const [currentSupply, setCurrentSupply] = useState('');
  const [coinTicker, setCoinTicker] = useState('');
  const [coinDescription, setCoinDescription] = useState('');
  const [trackerPage, setTrackerPage] = useState('');
  const [forkedTechnology, setForkedTechnology] = useState(''); // Define forkedTechnology
  const [miningAlgorithm, setMiningAlgorithm] = useState('');
  const [baseChain, setBaseChain] = useState('');
  const [totalMarketCap, setTotalMarketCap] = useState('');
  const [existenceDuration, setExistenceDuration] = useState('');
  const [changedHands, setChangedHands] = useState('');
  const [devTeamSize, setDevTeamSize] = useState('');
  const [platform, setPlatform] = useState('');
  const [chainFork, setChainFork] = useState('');
  const [teamBackers, setTeamBackers] = useState('');
  const [mediaCoverage, setMediaCoverage] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');
  const [sourceCode, setSourceCode] = useState('');
  const [whitePaper, setWhitePaper] = useState('');
  const [blockchainExplorers, setBlockchainExplorers] = useState('');
  const [bitcointalkPage, setBitcointalkPage] = useState('');
  const [messageBoard, setMessageBoard] = useState('');
  const [mobileApp, setMobileApp] = useState('');
  const [totalSupplyExchange, setTotalSupplyExchange] = useState('');
  const [discord, setDiscord] = useState('');
  const [telegram, setTelegram] = useState('');
  const [x, setX] = useState('');
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [instagram, setInstagram] = useState('');
  const [videoChannel, setVideoChannel] = useState('');
  const [maximumSupply, setMaximumSupply] = useState("");
  const [logo, setLogo] = useState(null);


  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');


  const [socialLinks, setSocialLinks] = useState({
    discord: '',
    telegram: '',
    x: '',
    facebook: '',
    linkedin: '',
    instagram: '',
    videoChannel: '',
  });

  // Handle change for social media links
  const handleSocialLinkChange = (platform, value) => {
    setSocialLinks(prevState => ({
      ...prevState,
      [platform]: value
    }));
  };

  // Handle file change for logo
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);
    }
  };


  const handleRegisterCoin = async () => {

    const formData = new FormData();
    formData.append('name', coinName);
    formData.append('ticker', coinTicker);
    formData.append('current_supply', currentSupply);
    formData.append('max_supply', maximumSupply);
    formData.append('type', coinType);
    formData.append('description', coinDescription);
    formData.append('market_cap', totalMarketCap);
    formData.append('tracker_page', trackerPage);
    formData.append('forked_technology', forkedTechnology);
    formData.append('mining_algorithm', miningAlgorithm);
    formData.append('base_chain', baseChain);
    formData.append('development_team_size', devTeamSize);
    formData.append('has_changed_hands', changedHands);
    formData.append('existence_duration', existenceDuration);
    formData.append('social_media_discord', socialLinks.discord);
    formData.append('social_media_telegram', socialLinks.telegram);
    formData.append('social_media_x', socialLinks.x);
    formData.append('social_media_facebook', socialLinks.facebook);
    formData.append('social_media_linkedin', socialLinks.linkedin);
    formData.append('social_media_instagram', socialLinks.instagram);
    formData.append('video_channel', socialLinks.videoChannel);
    formData.append('source_code', sourceCode);
    formData.append('white_paper', whitePaper);
    formData.append('blockchain_explorers', blockchainExplorers);
    formData.append('bitcointalk_ann', bitcointalkPage);
    formData.append('message_board', messageBoard);
    formData.append('mobile_app', mobileApp);
    formData.append('exchange_supply', totalSupplyExchange);
    formData.append('is_anonymous', isAnonymous);

    if (logo) {
      formData.append('logo', logo);
    }

    try {

      const data = {
        id: user.id,
        token: token,
        payloads: formData,
      };

      const response = await registerCoin(data, {
        headers: {
          'Content-Type': 'multipart/form-data' // Important for file uploads
        }
      });

      if (response.status === 200) {
        console.log(response);
        navigate("/dashboard/coin-profile");
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log("Error registering your new coin: ", error);
    }
  };



  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col pt-6 max-md:px-3 max-md:pt-0 self-stretch px-8 pb-6 max-md:mt-6 max-md:max-w-full">
        <div className="text-lg font-bold tracking-tight leading-5 text-blue-900 max-md:max-w-full">
          Register a Coin
        </div>
        <div className="flex flex-col justify-center mt-6 bg-white rounded-3xl max-md:max-w-full">
          <div className="flex flex-col p-6 rounded-lg border border-devRegDarkBlue bg-[#e3e7ee] max-md:px-5 max-md:max-w-full">
            <div className="text-sm leading-5 text-neutral-600 max-md:max-w-full">
              When registering your badge at CoinReg.info, you have the option
              to select your preferred level of privacy: <br /><br />
              <span className="font-bold">
                Yes, I Want to Remain Anonymous:
              </span>{" "}
              By selecting this option, your serial number will not be linked to
              your badge on CoinReg.info. This ensures your complete anonymity,
              and your identity will remain confidential. <br /><br />
              <span className="font-bold">
                No, I Don’t Mind Being Public:
              </span>{" "}
              If you choose this option, your badge on CoinReg.info will become
              interactive. It will link to your personal profile, allowing
              others to view your developer profile. This option is for those
              who wish to publicly display their registered developer
              status. Please select the option that best suits your privacy
              preferences. Your choice will be respected and securely
              implemented in our system
            </div>
            <div className="flex flex-col items-start gap-2 mt-4 max-md:flex-wrap max-md:pr-5">
              <div className="my-auto font-bold tracking-tight leading-6 text-blue-900">
                Do you want to be anonymous?{" "}
              </div>
              <div className="flex gap-4 text-base leading-5 whitespace-nowrap text-neutral-600">
                <div className="flex flex-col justify-center items-center">
                  <div
                    className="flex gap-1.5 p-1 cursor-pointer items-center"
                    onClick={() => setIsAnonymous(1)}
                  >
                    <div
                      className={`shrink-0 w-4 h-4 ${isAnonymous ? "bg-devRegDarkBlue" : "bg-neutral-400"} rounded-full`}
                    />
                    <div className={`${isAnonymous ? "font-bold text-black" : ""}`}>
                      Yes
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div
                    className="flex gap-2 px-1.5 py-1 cursor-pointer items-center"
                    onClick={() => setIsAnonymous(0)}
                  >
                    <div
                      className={`shrink-0 w-4 h-4 ${!isAnonymous ? "bg-devRegDarkBlue" : "bg-neutral-400"} rounded-full`}
                    />
                    <div className={`${!isAnonymous ? "font-bold text-black" : ""}`}>
                      No
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 max-md:max-w-full">
          <div className="flex gap-5 flex-col max-md:gap-0">
            <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch max-md:mt-2 relative gap-3 max-md:items-center">
                <div className="text-base tracking-tight leading-4 text-neutral-600">
                  Coin Logo
                </div>
                <div className="flex flex-col w-[15%] max-md:w-[128px] aspect-square h-auto grow overflow-hidden justify-center text-sm leading-4 text-center rounded-lg border border-solid border-neutral-300 text-stone-300 max-md:mt-0">
                  <img
                    loading="lazy"
                    src={logo ? URL.createObjectURL(logo) : 'https://cdn.builder.io/api/v1/image/assets/TEMP/4072f13a03724e0f0aaebabad129fe9d73bee02fda7b510be25b9e62e0e88fd8?'}
                    className="self-center w-11 aspect-[0.95]"
                  />
                  <div>Coin Logo</div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="flex flex-col w-full max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center text-base max-md:mt-4 max-md:max-w-full">
                <div className="flex gap-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Coin Name
                    </div>
                    <input
                      placeholder="Coin Name"
                      value={coinName}
                      onChange={(e) => setCoinName(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Current Supply
                    </div>
                    <input
                      placeholder="Current Supply"
                      value={currentSupply}
                      onChange={(e) => setCurrentSupply(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Coin Ticker
                    </div>
                    <input
                      placeholder="Coin Ticker"
                      value={coinTicker}
                      onChange={(e) => setCoinTicker(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Maximum Supply
                    </div>
                    <input
                      placeholder="Maximum Supply"
                      value={maximumSupply}
                      onChange={(e) => setMaximumSupply(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Coin Description
                    </div>
                    <textarea
                      placeholder="Coin Description"
                      value={coinDescription}
                      onChange={(e) => setCoinDescription(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 h-32"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Coin Tracker Page
                    </div>
                    <input
                      placeholder="Tracker Page Link"
                      value={trackerPage}
                      onChange={(e) => setTrackerPage(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Forked Technology
                    </div>
                    <input
                      placeholder="Forked Technology"
                      value={forkedTechnology}
                      onChange={(e) => setForkedTechnology(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Mining Algorithm
                    </div>
                    <input
                      placeholder="Mining Algorithm"
                      value={miningAlgorithm}
                      onChange={(e) => setMiningAlgorithm(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Base Chain
                    </div>
                    <input
                      placeholder="Base Chain"
                      value={baseChain}
                      onChange={(e) => setBaseChain(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Total Market Cap
                    </div>
                    <input
                      placeholder="Total Market Cap"
                      value={totalMarketCap}
                      onChange={(e) => setTotalMarketCap(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Existence Duration
                    </div>
                    <input
                      placeholder="Existence Duration"
                      value={existenceDuration}
                      onChange={(e) => setExistenceDuration(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Changed Hands
                    </div>
                    <input
                      placeholder="Changed Hands"
                      value={changedHands}
                      onChange={(e) => setChangedHands(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Dev Team Size
                    </div>
                    <input
                      placeholder="Dev Team Size"
                      value={devTeamSize}
                      onChange={(e) => setDevTeamSize(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Platform
                    </div>
                    <input
                      placeholder="Platform"
                      value={platform}
                      onChange={(e) => setPlatform(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Chain Fork
                    </div>
                    <input
                      placeholder="Chain Fork"
                      value={chainFork}
                      onChange={(e) => setChainFork(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Team/Backers/Investors
                    </div>
                    <input
                      placeholder="Team/Backers/Investors"
                      value={teamBackers}
                      onChange={(e) => setTeamBackers(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Media Coverage
                    </div>
                    <input
                      placeholder="Media Coverage"
                      value={mediaCoverage}
                      onChange={(e) => setMediaCoverage(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Website Link
                    </div>
                    <input
                      placeholder="Website Link"
                      value={websiteLink}
                      onChange={(e) => setWebsiteLink(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Source Code
                    </div>
                    <input
                      placeholder="Source Code"
                      value={sourceCode}
                      onChange={(e) => setSourceCode(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      White Paper
                    </div>
                    <input
                      placeholder="White Paper"
                      value={whitePaper}
                      onChange={(e) => setWhitePaper(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Blockchain Explorers
                    </div>
                    <input
                      placeholder="Blockchain Explorers"
                      value={blockchainExplorers}
                      onChange={(e) => setBlockchainExplorers(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Bitcointalk Page
                    </div>
                    <input
                      placeholder="Bitcointalk Page"
                      value={bitcointalkPage}
                      onChange={(e) => setBitcointalkPage(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Message Board
                    </div>
                    <input
                      placeholder="Message Board"
                      value={messageBoard}
                      onChange={(e) => setMessageBoard(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Mobile App
                    </div>
                    <input
                      placeholder="Mobile App"
                      value={mobileApp}
                      onChange={(e) => setMobileApp(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div className="tracking-tight leading-[110%] text-neutral-600">
                      Total Supply on Exchanges
                    </div>
                    <input
                      placeholder="Total Supply on Exchanges"
                      value={totalSupplyExchange}
                      onChange={(e) => setTotalSupplyExchange(e.target.value)}
                      className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-4 max-md:flex-wrap">
                  <div className="flex flex-col flex-1 justify-center">
                    <div>
                      <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
                        Social Media Links
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-3">
                        <div className="relative w-full">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/218c5aa884054468f470bc17078edfbc0bf4137cab1c2759bc00d808e587045d?"
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 w-6 h-auto"
                          />
                          <input
                            placeholder="Discord"
                            value={socialLinks.discord}
                            onChange={(e) => handleSocialLinkChange('discord', e.target.value)}
                            className="flex-1 w-full p-4 pl-12 rounded-lg border border-solid border-neutral-300"
                          />
                        </div>
                        <div className="relative w-full">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5b9159362b172d08a761a818e5f6d4345038623a01019244c21348be08033587?"
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 w-6 h-auto"
                          />
                          <input
                            placeholder="Telegram"
                            value={socialLinks.telegram}
                            onChange={(e) => handleSocialLinkChange('telegram', e.target.value)}
                            className="flex-1 w-full p-4 pl-12 rounded-lg border border-solid border-neutral-300"
                          />
                        </div>
                        <div className="relative w-full">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a3894aa2ae0ffebab862be6d4793e0bcc386998b0616edce5ecc65280e2a3e8?"
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 w-6 h-auto"
                          />
                          <input
                            placeholder="X"
                            value={socialLinks.x}
                            onChange={(e) => handleSocialLinkChange('x', e.target.value)}
                            className="flex-1 w-full p-4 pl-12 rounded-lg border border-solid border-neutral-300"
                          />
                        </div>
                        <div className="relative w-full">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b33c94fd3bf980e16502a36af4edf69ac70090a19cde526181d74c0792656d5e?"
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 w-6 h-auto"
                          />
                          <input
                            placeholder="Facebook"
                            value={socialLinks.facebook}
                            onChange={(e) => handleSocialLinkChange('facebook', e.target.value)}
                            className="flex-1 w-full p-4 pl-12 rounded-lg border border-solid border-neutral-300"
                          />
                        </div>
                        <div className="relative w-full">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0ef0b87a6e82f59c4f9a420d3ced727af26963d691f330fa6c6121f6624daf38?"
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 w-6 h-auto"
                          />
                          <input
                            placeholder="Linkedin"
                            value={socialLinks.linkedin}
                            onChange={(e) => handleSocialLinkChange('linkedin', e.target.value)}
                            className="flex-1 w-full p-4 pl-12 rounded-lg border border-solid border-neutral-300"
                          />
                        </div>
                        <div className="relative w-full">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f42401acdcb59fe8f6b562b740b6c64007bed9e03537ad931f0708c3365a4ca0?"
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 w-6 h-auto"
                          />
                          <input
                            placeholder="Instagram"
                            value={socialLinks.instagram}
                            onChange={(e) => handleSocialLinkChange('instagram', e.target.value)}
                            className="flex-1 w-full p-4 pl-12 rounded-lg border border-solid border-neutral-300"
                          />
                        </div>
                        <div className="relative w-full">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/71b51482efd4126eaf9a563ce0069ffae75cab04f10747d521df6529aad1ad65?"
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 w-6 h-auto"
                          />
                          <input
                            placeholder="Video Channel (e.g. YouTube) (optional)"
                            value={socialLinks.videoChannel}
                            onChange={(e) => handleSocialLinkChange('videoChannel', e.target.value)}
                            className="flex-1 w-full p-4 pl-12 rounded-lg border border-solid border-neutral-300"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-2 px-6 py-6 max-md:flex-col max-md:px-0">
            <div
              className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
              onClick={() => navigate("/dashboard/coin-profile")}
            >
              Cancel
            </div>
            <button
              onClick={handleRegisterCoin}
              className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
            >
              Register Coin
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterCoinMain;
