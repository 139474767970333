import React, { useEffect } from "react";
import { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { motion } from "framer-motion";
function Whybenefit() {
  const [slidesPerView, setSlidesPerView] = useState(2);

  const content = [
    {
      title: "Exclusive Learning Opportunities",
      id: 1,
      description:
        "Join us and gain access to a wealth of webinars, workshops, and online courses. Develop your skills and stay ahead with the latest technologies and trends in the industry.",
    },

    {
      title: "Premium Tools and Resources",
      id: 2,
      description:
        "As a member, you can access our hand-picked selection of code libraries, templates, and development tools. Enjoy cloud resources and discounted software subscriptions tailored to your needs.",
    },

    {
      title: `Vibrant Community and Networking`,
      id: 3,
      description:
        " Be a part of our strong and supportive community. Seize opportunities for networking, mentorship, and collaboration, and engage with peers who share your passion for development. ",
    },
    {
      title: "Recognition and Badges",
      id: 4,
      description:
        "We value your contributions. That's why we've implemented a badges and ranks system that showcases your activity, experience, and contributions to our community.",
    },
  ];
  const content2 = [
    {
      title: "Competitions and Challenges",
      id: 5,
      description:
        "Showcase your skills in our regular coding competitions, hackathons, and challenges. With attractive prizes up for grabs, it's not just about the thrill of competition but also the joy of recognition.",
    },

    {
      title: "Job Board and Freelance Opportunities",
      id: 6,
      description:
        "Take advantage of our job board, which features opportunities from our partner companies. We also list freelance projects, allowing you to gain real-world experience and earn while you learn.",
    },

    {
      title: `Discounts and Perks`,
      id: 7,
      description:
        "As a valued member, you'll benefit from discounts on products, services, and events through our partnerships with relevant companies in the industry.",
    },
    {
      title: "Your Voice Matters",
      id: 8,
      description:
        "Have your say regarding our website, products, and community. Through polls, surveys, and open forums, your suggestions and feedback can shape our collective future.",
    },
  ];
  const combinedContent = [...content, ...content2];
  useEffect(() => {
    function handleResize() {
      const newSlidesPerView = window.innerWidth >= 768 ? 2 : 1;
      setSlidesPerView(newSlidesPerView);
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="mt-10 max-w-main m-auto py-5 m-auto px-10 max-md:px-3">
      <div className=" mt-10 max-w-main m-auto">
        <div className="px-2 text-[30px] w-full font-bold leading-9 max-md:leading-7 max-md:text-sm20 text-devRegDarkBlue mt-4">
          We want the developers to feel they have a home and can ask questions
          in the chat and forums.
        </div>
        <p className="w-full px-2 text-sm15 mt-1">
          We want the developers to feel they have a home and can ask questions
          in the chat and forums. As an incentive to be a member,
        </p>
      </div>
      <div className="min-[1210px]:hidden pb-20">
        <Swiper
          centeredSlides={true}
          spaceBetween={5}
          slidesPerView={slidesPerView}
          className="mySwiper mt-12 px-5"
          initialSlide={1}
        >
          {combinedContent.map((item, index) => (
            <SwiperSlide key={index}>
              <motion.div className="w-full border-[3px] border-devRegDarkBlue rounded-t-lg">
                <div
                  className={`border-devRegDarkBlue shadow-lg border-b-[7px] m-auto w-full h-[380px] overflow-y-hidden 
                bg-white  text-devRegBlack relative px-[32px] py-[32px] cursor-pointer rounded-t-lg`}
                  style={{ transition: "background-color 0.3s, color 0.3s" }}
                >
                  <div className="flex flex-col">
                    <div className="flex gap-5 mb-4 flex-col">
                      <div>
                        <img src="/checkboxRegister.png" alt="" />
                      </div>
                      <div className="text-2xl font-semibold tracking-tighter">
                        {item.title}
                      </div>
                    </div>
                    <p
                      className={`text-[#626262] 
                h-auto`}
                    >
                      {item.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="max-[1210px]:hidden max-w-main m-auto px-3">
        <div className="flex gap-8 mt-12 justify-between">
          {content.map((item, index) => (
            <div key={index}>
              <div className="w-[290px] border-[3px] border-devRegDarkBlue rounded-t-lg">
                <div
                  className={`border-devRegDarkBlue  shadow-lg border-b-[7px] m-auto w-full h-[380px] overflow-y-hidden 
                bg-white  text-devRegBlack relative px-[12px] py-[32px] cursor-pointer rounded-t-lg`}
                  style={{ transition: "background-color 0.3s, color 0.3s" }}
                >
                  <div className="flex flex-col">
                    <div className="flex gap-5 mb-4 flex-col">
                      <div>
                        <img src="/checkboxRegister.png" alt="" />
                      </div>
                      <div className="text-2xl font-semibold tracking-tighter">
                        {item.title}
                      </div>
                    </div>
                    <p
                      className={`text-[#626262] 
                h-auto`}
                    >
                      {item.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex mt-12 justify-between">
          {content2.map((item, index) => (
            <div key={index}>
              <div className="w-[290px] border-[3px] border-devRegDarkBlue rounded-t-lg">
                <div
                  className={`border-devRegDarkBlue  shadow-lg border-b-[7px] m-auto w-full h-[380px] overflow-y-hidden 
                bg-white  text-devRegBlack relative px-[12px] py-[32px] cursor-pointer rounded-t-lg`}
                  style={{ transition: "background-color 0.3s, color 0.3s" }}
                >
                  <div className="flex flex-col">
                    <div className="flex gap-5 mb-4 flex-col">
                      <div>
                        <img src="/checkboxRegister.png" alt="" />
                      </div>
                      <div className="text-2xl font-semibold tracking-tighter">
                        {item.title}
                      </div>
                    </div>
                    <p
                      className={`text-[#626262] 
                h-auto`}
                    >
                      {item.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Whybenefit;
