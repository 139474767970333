import React from "react";
import TopBanner from "../components/TopBanner";
import ContactUs from "./ContactUs";

function ContactMain() {
  return (
    <div className="bg-[#F9FAFB]">
      <TopBanner imgBg={"/ContactPageHeader-BG.png"} center={true} />

      <ContactUs />
    </div>
  );
}

export default ContactMain;