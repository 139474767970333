import axios from 'axios';

const publicApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL, 
  headers: {
    'Content-Type': 'application/json',
  },
});

const authenticatedApi = (token, file = false) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
  };

  if (file) {
    headers['Content-Type'] = 'multipart/form-data';
  } else {
    headers['Content-Type'] = 'application/json';
  }

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
    timeout: 300000,
  });
};

// Function to handle API calls
const get = (apiInstance, url, config = {}) => apiInstance.get(url, config);
const post = (apiInstance, url, data, config = {}) => apiInstance.post(url, data, config);
const put = (apiInstance, url, data, config = {}) => apiInstance.put(url, data, config);
const del = (apiInstance, url, config = {}) => apiInstance.delete(url, config);

// Public requests
export const login = (data) => post(publicApi, '/api/login', data);
export const forgetPasswordSendOtpEmail = (data) => post(publicApi, '/api/otp/forgot-password', data.payloads);
export const forgetPasswordVerifyOtpEmail = (data) => post(publicApi, '/api/otp/verify', data.payloads);
export const forgetPasswordResetPassword = (data) => post(publicApi, '/api/reset-password', data.payloads);
export const forgetPasswordResetPasswordConfirm = (data) => post(publicApi, 'api/reset-password-confirm', data.payloads);
export const getProfessions = (data) => get(publicApi, `/api/professions-by-industry/${data.industry}?${data.params}`);



//                                        ** Authenticated requests **
// ================================================= Email Verification =================================================
export const checkEmailVerification = (data) => post(authenticatedApi(data.token), `/api/profile`);

// ================================================= Access Key =================================================
export const verifyAccessKey = (data) => post(authenticatedApi(data.token), 'api/verify-access-key', data.payloads);
export const accessKeyUploadDocuments = (data) => post(authenticatedApi(data.token), 'api/verify-access-key', data.payloads);

// ================================================= 2FA =================================================
export const enable2FA = (data) => post(authenticatedApi(data.token), `/api/2fa/enable`);
export const verifyOTP2FA = (data) => post(authenticatedApi(data.token), `/api/otp/verify`, data.payloads);
export const generatetRecoveryCodes = (data) => post(authenticatedApi(data.token), `/api/2fa/recovery-codes`, data.payloads);
export const getRecoveryCodes = (data) => get(authenticatedApi(data.token), `/api/2fa/recovery-codes`);

// ================================================= Guardian =================================================
export const submitGuardianInformation = (data) => post(authenticatedApi(data.token), `/api/guardians`, data.payloads);
export const uploadGuardianDocuments = (data) => post(authenticatedApi(data.token, true), `/api/documents?guardian_document=1`, data.payloads);
export const deleteGuardianDocument = (data) => del(authenticatedApi(data.token), `/api/documents/${data.documentId}`);
export const resetGuardianDocuments = (data) => post(authenticatedApi(data.token), `/api/documents/reset-uploads?guardian_document=1`);
export const compileGuradianDocuments = (data) => post(authenticatedApi(data.token), `/api/documents/compile?guardian_document=1`);

// ================================================= Face Recognition =================================================
export const checkFaceRecognitionStatus = (data) => get(authenticatedApi(data.token), `/api/face-recognition/status`);
export const getFaceRcognitionQR = (data) => get(authenticatedApi(data.token), `/api/qr-code?used_for=${data.for}`);
export const scanAndVerify = (data) => post(publicApi, `/api/face-verify`, data.payloads);

// ================================================= Payment =================================================
export const getMemberSubscription = (data) => get(authenticatedApi(data.token), `/api/subscriptions/key`);
export const validateCoupon = (data) => post(authenticatedApi(data.token), `/api/coupon-codes/${data.coupon}/validate`);
export const processPayment = (data) => post(authenticatedApi(data.token), `/api/subscriptions/registration-payment`, data.payloads);
export const confirmPayment = (data) => post(authenticatedApi(data.token), `/api/subscriptions/send-payment-receipt`, data.payloads);

// ================================================= Upload documents =================================================
export const validateDocument = (data) => post(authenticatedApi(data.token, true), `/api/documents?${data.params}`, data.payloads);
export const compileDocuments = (data) => post(authenticatedApi(data.token), `/api/documents/compile`);
export const deleteDocument = (data) => del(authenticatedApi(data.token), `/api/documents/${data.documentId}`);
export const resetDocument = (data) => post(authenticatedApi(data.token), `/api/documents/reset-uploads`);

// ================================================= Interview =================================================
export const setInterviewSchedule = (data) => post(authenticatedApi(data.token), `/api/interview-schedules`, data.payloads);

// ================================================= Forum =================================================
export const getCategories = (data) => get(authenticatedApi(data.token), `/api/forum/topic-categories`);
export const getTags = (data) => get(authenticatedApi(data.token), `/api/forum/topic-tags`);
export const getPosts = (data) => get(authenticatedApi(data.token), `/api/forum/topic?page=${data.page}`);
export const addNewPost = (data) => post(authenticatedApi(data.token), `/api/forum/topic`, data.payloads);
export const getPost = (data) => get(authenticatedApi(data.token), `/api/forum/topic/${data.postId}`);
export const getSavedPosts = (data) => get(authenticatedApi(data.token), `/api/forum/topic-saved`);
export const savePost = (data) => post(authenticatedApi(data.token), `/api/forum/save-topic`, data.payloads);
export const unsavePost = (data) => del(authenticatedApi(data.token), `/api/forum/unsave-topic/${data.postId}`);
export const addComment = (data) => post(authenticatedApi(data.token), `/api/forum/add-comment/${data.postId}`, data.payloads);
export const replyComment = (data) => post(authenticatedApi(data.token), `/api/forum/reply-to-comment/${data.commentId}`, data.payloads);
export const voteTopic = (data) => post(authenticatedApi(data.token), `/api/forum/topic-vote/${data.topicId}`, data.payloads);
export const voteComment = (data) => post(authenticatedApi(data.token), `/api/forum/comment-vote/${data.commentId}`, data.payloads);
export const voteReply = (data) => post(authenticatedApi(data.token), `/api/forum/comment-reply-vote/${data.replyId}`, data.payloads);
export const removeVote = (data) => del(authenticatedApi(data.token), `/api/forum/remove-vote/${data.votedId}`);

// ================================================= Messages =================================================
// The Register
export const getTheRegisterMessages = (data) => get(authenticatedApi(data.token), `/api/messages/received?platform=register&page=${data.page}`);
export const getTheRegisterConversation = (data) => get(authenticatedApi(data.token), `/api/conversations/${data.conversationId}?${data.params}`);
export const replyTheRegisterMessage = (data) => post(authenticatedApi(data.token), `/api/messages?platform=register`, data.payloads);
export const deleteTheRegisterMessage = (data) => del(authenticatedApi(data.token), `/api/messages/${data.messageType}/${data.messageId}?platform=register`);
export const bulkDeleteTheRegisterMessage = (data) => post(authenticatedApi(data.token), `/api/messages/${data.messageType}?platform=register`, data.payloads);
export const markAsReadUnreadTheRegisterMessage = (data) => post(authenticatedApi(data.token), `/api/messages/${data.messageType}/mark-read-unread?platform=register`, data.payloads);

// Devreg
export const getDevregMessages = (data) => get(authenticatedApi(data.token), `/api/messages/received?platform=devreg&page=${data.page}`);
export const getDevregConversation = (data) => get(authenticatedApi(data.token), `/api/conversations/${data.conversationId}?${data.params}`);
export const replyDevregMessage = (data) => post(authenticatedApi(data.token), `/api/messages?platform=devreg`, data.payloads);
export const deleteDevregMessage = (data) => del(authenticatedApi(data.token), `/api/messages/${data.messageType}/${data.messageId}?platform=devreg`);
export const bulkDeleteDevregMessage = (data) => post(authenticatedApi(data.token), `/api/messages/${data.messageType}?platform=devreg`, data.payloads);
export const markAsReadUnreadDevregMessage = (data) => post(authenticatedApi(data.token), `/api/messages/${data.messageType}/mark-read-unread?platform=devreg`, data.payloads);

// Admin
export const getAdminMessages = (data) => get(authenticatedApi(data.token), `/api/messages/received?platform=admin&page=${data.page}`);
export const getAdminConversation = (data) => get(authenticatedApi(data.token), `/api/conversations/${data.conversationId}?${data.params}`);
export const replyAdminMessage = (data) => post(authenticatedApi(data.token), `/api/messages?platform=admin`, data.payloads);
export const deleteAdminMessage = (data) => del(authenticatedApi(data.token), `/api/messages/${data.messageType}/${data.messageId}?platform=admin`);
export const bulkDeleteAdminMessage = (data) => post(authenticatedApi(data.token), `/api/messages/${data.messageType}?platform=admin`, data.payloads);
export const markAsReadUnreadAdminMessage = (data) => post(authenticatedApi(data.token), `/api/messages/${data.messageType}/mark-read-unread?platform=admin`, data.payloads);

// ================================================= Referrals =================================================
export const getMyReferrals = (data) => get(authenticatedApi(data.token), `/api/earnings-and-referrals/summary`);

// ================================================= Professional Profile =================================================
// Edit profile
export const getProfile = (data) => get(authenticatedApi(data.token), `/api/profile/overview`);
export const updateProfile = (data) => post(authenticatedApi(data.token, true), `/api/profile/overview`, data.payloads);
export const getFeatures = (data) => get(authenticatedApi(data.token,), `/api/profile/features`);
export const addFeature = (data) => post(authenticatedApi(data.token, true), `/api/profile/features`, data.payloads);
export const updateFeature = (data) => post(authenticatedApi(data.token, true), `/api/profile/features/${data.featureId}`, data.payloads);
export const deleteFeature = (data) => del(authenticatedApi(data.token), `/api/profile/features/${data.featureId}`);
export const getLanguages = (data) => get(authenticatedApi(data.token), `/api/profile/languages`);
export const addLanguage = (data) => post(authenticatedApi(data.token), `/api/profile/languages`, data.payloads);
export const deleteLanguage = (data) => del(authenticatedApi(data.token), `/api/profile/languages/${data.languageId}`);
export const getWorkHistory = (data) => get(authenticatedApi(data.token), `/api/profile/work-histories`);
export const addWorkHistory = (data) => post(authenticatedApi(data.token, true), `/api/profile/work-histories`, data.payloads);
export const editWorkHistory = (data) => post(authenticatedApi(data.token, true), `api/profile/work-histories/${data.workHistoryId}`, data.payloads);
export const deleteWorkHistory = (data) => del(authenticatedApi(data.token), `/api/profile/work-histories/${data.workHistoryId}`);
export const addWorkHistoryProjects = (data) => post(authenticatedApi(data.token, true), `/api/profile/work-histories/${data.workHistoryId}/projects`, data.payloads);
export const getPortfolios = (data) => get(authenticatedApi(data.token), `/api/profile/portfolios`);
export const addPortfolio = (data) => post(authenticatedApi(data.token, true), `/api/profile/portfolios`, data.payloads);
export const updatePortfolio = (data) => post(authenticatedApi(data.token, true), `/api/profile/portfolios/${data.portfolioId}`, data.payloads);
export const deletePortfolio = (data) => del(authenticatedApi(data.token), `/api/profile/portfolios/${data.portfolioId}`);
export const addPortfolioImages = (data) => post(authenticatedApi(data.token, true), `/api/profile/portfolios/${data.portfolioId}/images`, data.payloads);
export const deletePortfolioImage = (data) => del(authenticatedApi(data.token), `/api/profile/portfolios/${data.portfolioId}/images/${data.portfolioImageId}`);

export const getSkills = (data) => get(authenticatedApi(data.token), `/api/profile/skills`);
export const addSkill = (data) => post(authenticatedApi(data.token), `/api/profile/skills`, data.payloads);
export const deleteSkill = (data) => del(authenticatedApi(data.token), `/api/profile/skills/${data.skillId}`);
export const getEducationAndCertificates = (data) => get(authenticatedApi(data.token), `/api/profile/educations`);
export const addEducationAndCertificate = (data) => post(authenticatedApi(data.token, true), `/api/profile/educations`, data.payloads);
export const updateEducationAndCertificate = (data) => post(authenticatedApi(data.token, true), `/api/profile/educations/${data.educAndCertId}`, data.payloads);
export const deleteEducationAndCertificate = (data) => del(authenticatedApi(data.token), `/api/profile/educations/${data.educAndCertId}`);
export const getHonorsAndAwards = (data) => get(authenticatedApi(data.token), `/api/profile/awards`);
export const addHonorAndAward = (data) => post(authenticatedApi(data.token, true), `/api/profile/awards`, data.payloads);
export const updateHonorAndAward = (data) => post(authenticatedApi(data.token, true), `/api/profile/awards/${data.honorAndAwardId}`, data.payloads);
export const deleteHonorAndAward = (data) => del(authenticatedApi(data.token, true), `/api/profile/awards/${data.honorAndAwardId}`);

export const getProfilePreview = (data) => post(authenticatedApi(data.token), `/api/profile/preview`, data.payloads);

// Certificate
export const downloadCertificate = (data) => get(authenticatedApi(data.token), `/api/profile/download-certificate`);

// ================================================= Coin Profile =================================================
export const getRegisteredCoins = (data) => get(authenticatedApi(data.token), `api/coin-register/coins`);
export const getRegisteredCoin = (data) => get(authenticatedApi(data.token), `api/coin-register/coins/${data.coinId}`);
export const registerCoin = (data) => post(authenticatedApi(data.token,true), `api/coin-register/coins`, data.payloads);
export const updateCoin = (data) => post(authenticatedApi(data.token, true), `/api/coin-register/coins/${data.coinId}`, data.payloads);

// ================================================= Security Settings =================================================
// Change password
export const changePassword = (data) => post(authenticatedApi(data.token), `/api/change-password`, data.payloads);
// Check password
export const checkPassword = (data) => post(authenticatedApi(data.token), `/api/check-password`, data.payloads);
// Change Phone number
export const sendOtpPhone = (data) => post(authenticatedApi(data.token), `/api/otp/sms`, data.payloads);
export const verifyOtpPhone = (data) => post(authenticatedApi(data.token), `/api/otp/verify`, data.payloads);
export const sendOtpNewPhone = (data) => post(authenticatedApi(data.token), `/api/change-phone-number`, data.payloads);
export const verifyOtpNewPhone = (data) => post(authenticatedApi(data.token), `/api/otp/verify`, data.payloads);
// Change Email
export const sendOtpEmail = (data) => post(authenticatedApi(data.token), `/api/otp/email`, data.payloads);
export const verifyOtpEmail = (data) => post(authenticatedApi(data.token), `/api/otp/verify`, data.payloads);
export const sendOtpNewEmail = (data) => post(authenticatedApi(data.token), `/api/change-email`, data.payloads);
export const verifyOtpNewEmail = (data) => put(authenticatedApi(data.token), `api/user/${data.id}/otp-via-new-email-verify`, data.payloads);
// Send Feedback
export const contactAdmin = (data) => post(authenticatedApi(data.token), `api/send/${data.type}`, data.payloads);

//Profession
export const searchProfession = (data) => get(authenticatedApi(data.token), `/api/professions/${data.search}`);

// ================================================= Logout =================================================
export const logout = (data) => post(authenticatedApi(data.token), `/api/logout`);


export default {
  publicApi,
  authenticatedApi,
};
