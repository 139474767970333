import React, { useState, useEffect } from "react";
import { CiUser } from "react-icons/ci";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { Country, State, City } from "country-state-city";


function MeetTheStaffs({ teamMembers, loading }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 321);
  const [countries, setCountries] = useState([]);
  const [managers, setManagers] = useState([]);
  
  const [filterCountry, setFilterCountry] = useState("");
  const currentData = teamMembers;
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData =
  currentData.length > 0
    ? currentData.filter((item) => {
        const matchesSearchQuery =
          (item.fullname
            ? item.fullname.toString().toLowerCase()
            : ""
          ).includes(searchQuery.toLowerCase()) ||
          (item.country !== undefined
            ? item.country.toString().toLowerCase()
            : ""
          ).includes(searchQuery.toLowerCase()) ||
          (item.country_code
            ? item.country_code.toString().toLowerCase()
            : ""
          ).includes(searchQuery.toLowerCase()) ||
          (item.position !== undefined
            ? item.position.toString().toLowerCase()
            : ""
          ).includes(searchQuery.toLowerCase());

        const matchesFilterCountry =
          filterCountry === "" ||
          (item.country !== undefined &&
            item.country.toString().toLowerCase() ===
              filterCountry.toLowerCase());

        return matchesSearchQuery && matchesFilterCountry;
      })
    : [];
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = isMobile ? 1 : 8;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getPaginationNumbers = () => {
    const maxVisible = 3;
    if (totalPages <= maxVisible || !isMobile) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    }
    const paginationNumbers = [];
    const start = Math.max(1, currentPage - 1);
    const end = Math.min(totalPages, currentPage + 1);

    for (let i = start; i <= end; i++) {
      paginationNumbers.push(i);
    }

    if (currentPage > 1) paginationNumbers.unshift("...");
    if (currentPage < totalPages) paginationNumbers.push("...");

    return paginationNumbers;
  };

  return (
    <div className={`flex flex-col pt-10 pb-20 items-center max-md:pt-0 max-md:px-5 ${isMobile ? "px-4" : "px-10"}`}>
      <div className={`flex flex-col ${isMobile ? "max-w-full px-0" : "max-w-main px-0"} w-full`}>
        <div className="text-3xl font-bold text-blue-900 text-start max-[425px]:text-center max-md:mb-6">
          Meet The Country Managers and Senior Country Managers
        </div>
        <div className={`flex flex-wrap gap-4 items-center mt-4 w-full ${isMobile ? "justify-center flex-col" : ""}`}>
          <div className={`relative flex items-center text-base border border-gray-400 rounded-lg text-gray-400 bg-transparent ${isMobile ? "w-full max-w-full" : ""} max-md:w-full`}>
            <div className="absolute left-2">
              <CiUser size={25} />
            </div>
            <input
              type="text"
              placeholder="Search Name, Position, etc."
              className={`pl-10 pr-8 py-4 text-base text-gray-400 outline-none border-none bg-transparent w-full max-md:w-full`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className={`relative flex items-center my-auto rounded-lg border border-gray-400 ${isMobile ? "w-full max-w-full" : "min-w-[300px]"} max-md:w-full`}>
            <select value={filterCountry} onChange={(e) => setFilterCountry(e.target.value)} className={`flex-1 px-3 py-4 text-base border-none text-gray-400 outline-none bg-transparent ${isMobile ? "w-full max-w-full" : ""}`}>
              <option disabled value="">
                Country
              </option>
              {countries.map((country) => (
                <option key={country.isoCode} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
            {filterCountry && (
              <button
                onClick={() => setFilterCountry("")}
                className="absolute top-1/2 right-7 transform -translate-y-1/2 text-gray-400 hover:text-black"
                aria-label="Clear selection"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="#6c6c6c">
                  <path
                    fillRule="evenodd"
                    d="M10 9.293l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414L10 8.586z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-10 w-full">
          {currentItems.length === 0 ? (
            <div className="w-full text-xl font-semibold text-gray-500 text-center self-center">No results found</div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-12 justify-between text-center w-full max-w-full mt-4 m-auto max-md:gap-6">
              {currentItems.map((manager, index) => (
                <div key={index} className="flex flex-col items-center justify-center rounded-lg">
                  <img
                    loading="lazy"
                    src={manager.user?.profile_photo_path ?? '/devreg-default-avatar-member.png'}
                    className="object-contain max-w-full rounded-full border border-gray-300 aspect-square w-[150px] max-md:w-[100px]"
                    alt={`${manager.first_name}'s photo`}
                  />
                  <div className="flex flex-col mt-4 w-full text-center">
                    <div className="text-2xl font-bold tracking-tight text-blue-900 max-md:text-[18px]">{manager.fullname}</div>
                    <div className="flex gap-2 items-center justify-center mt-1 text-base text-devRegDarkBlue max-md:flex-col">
                      <div className="flex items-start gap-1">
                        {Array.isArray(manager.country_code) ? (
                          manager.country_code.map((code, idx) => (
                            <React.Fragment key={code}>
                              <img
                                src={`https://flagsapi.com/${code}/flat/64.png`}
                                alt={`Flag of ${manager.country[idx]}`}
                                className="w-[24px]"
                              />
                              {idx < manager.country_code.length - 1 && (
                                <span className="mx-1 text-gray-500"> | </span>
                              )}
                            </React.Fragment>
                          ))
                        ) : (
                          <img
                            src={`https://flagsapi.com/${manager.country_code}/flat/64.png`}
                            alt={`Flag of ${manager.country}`}
                            className="w-[24px]"
                          />
                        )}
                      </div>
                      <div className="text-[16px] max-md:text-[15px]">{manager.position}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-center mt-10">
            <nav>
              <ul className="flex items-center gap-4">
                <li>
                  <button onClick={prevPage} className={`px-2 py-2 ${currentPage === 1 ? "text-gray-400" : "text-gray-700"} rounded-lg`} disabled={currentPage === 1}>
                    <GoChevronLeft size={35} />
                  </button>
                </li>
                {getPaginationNumbers().map((number, index) => (
                  <li key={index} className="mx-1">
                    {number === "..." ? (
                      <span className="text-gray-500">{number}</span>
                    ) : (
                      <button
                        onClick={() => paginate(number)}
                        className={`px-3.5 py-1 text-lg ${currentPage === number ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"} rounded-lg`}
                      >
                        {number}
                      </button>
                    )}
                  </li>
                ))}
                <li>
                  <button onClick={nextPage} className={`px-2 py-2 ${currentPage === totalPages ? "text-gray-400" : "text-gray-700"} rounded-lg`} disabled={currentPage === totalPages}>
                    <GoChevronRight size={35} />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

      </div>
    </div>
  );
}

export default MeetTheStaffs;