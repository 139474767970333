import { motion } from "framer-motion";
import { useState } from "react";

const TopHeroCard = ({
  title,
  initialText,
  detailedText,
  unhoveredLogo,
  hoveredLogo,
  colorBg,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <motion.div
        className={`${
          isHovered ? `bg-${colorBg} py-[20px]` : "bg-white py-[60px]"
        } m-auto z-10 shadow-lg h-[240px] w-[385px]  overflow-y-hidden  border-[#0082BA] relative px-[32px]  cursor-pointer`}
        initial={{ rotateX: 0 }}
        animate={{ rotateX: isHovered ? 180 : 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="flex flex-col">
          {!isHovered && (
            <>
              <motion.img
                src={isHovered ? hoveredLogo : unhoveredLogo}
                alt={`${title} card for the hero section`}
                width={69}
                height={65}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="transition-all duration-300 absolute top-8 left-8"
              />
              <div className="flex items-center justify-between mt-[60px] mb-4 ">
                <h2 className="text-2xl font-semibold tracking-tighter">
                  {title}
                </h2>
              </div>
            </>
          )}

          {!isHovered && (
            <motion.p
              className="text-[#707070] "
              initial={{ height: "auto" }}
              animate={{ height: isHovered ? "auto" : "1.5rem" }}
              transition={{ duration: 0.5 }}
            >
              {initialText}
            </motion.p>
          )}
          {isHovered && (
            <motion.p
              className={` text-white mt-2`}
              initial={{ opacity: 0, rotateX: 0 }}
              animate={{ opacity: 1, rotateX: isHovered ? 180 : 0 }}
              transition={{ duration: 0.5 }}
            >
              {detailedText}
            </motion.p>
          )}
          {isHovered && (
            <>
              <motion.div
                initial={{ opacity: 0, rotateX: 0 }}
                animate={{ opacity: 1, rotateX: isHovered ? 180 : 0 }}
                transition={{ duration: 0.5 }}
                className={`  flex items-center justify-between mb-[60px] mt-4 `}
              >
                {/* <h2 className="text-2xl font-semibold tracking-tighter">
                  {title}
                </h2> */}
              </motion.div>

              {/* <motion.img
                src={isHovered ? hoveredLogo : unhoveredLogo}
                alt={`${title} card for the hero section`}
                width={69}
                height={65}
                initial={{ opacity: 0, rotateX: 0 }}
                animate={{ opacity: 1, rotateX: isHovered ? 180 : 0 }}
                transition={{ duration: 0.1 }}
                className="transition-all duration-300 absolute bottom-5 left-8"
              /> */}
            </>
          )}
        </div>
      </motion.div>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="bg-transparent z-20 w-full  h-[250px] absolute cursor-pointer  left-0 top-0"
      ></div>
      <div className={`w-[385px] mx-9px m-auto  h-[7px] bg-${colorBg}`}></div>
    </div>
  );
};

export default TopHeroCard;
