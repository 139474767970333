import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import backgroundImg from "../assets/img-home/HomePageHeader-BG.png"; // Ensure correct path
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import TopHeroCard from "../../../component/TopHeroBanner";
import MidHeroCard from "../../../component/MidHeroCard";
import ThreeStepRegistration from "./ThreeStepRegistration";
import UnlockingOpportunities from "./UnlockingOpportunities";
import BecomeRecognized from "../components/BecomeRecognized";

function Home() {
  const [slidesPerView, setSlidesPerView] = useState(3);
  const navigate = useNavigate();
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1180) {
        setSlidesPerView(3);
        return;
      }
      if (window.innerWidth >= 768) {
        setSlidesPerView(2);
        return;
      }
      if (window.innerWidth < 768) {
        setSlidesPerView(1);
        return;
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const detailTextsCard1 = `As part of the DevReg.org community, your verified status can significantly boost your professional reputation. Our robust validation process ensures that all members of our platform are recognized for their genuine skills and qualifications and "They Are Who They Say They Are"`;
  const detailTextsCard2 = `Clients look for reliability and trustworthiness in their developers. By being a part of our secure database of verified professionals, you demonstrate commitment to transparency and trustworthiness. This enhanced credibility can lead to stronger client relationships and more opportunities`;
  const detailTextsCard3 = `Join a network of like-minded individuals where you can collaborate, share ideas, and foster professional relationships. Being part of DevReg.org means you're part of a supportive community that values professional growth and development.`;

  return (
    <div className="bg-[#F9FAFB]">
      <section
        style={{
          width: "100%",
          backgroundImage: `url(${backgroundImg})`,
          height: "450px",
          margin: "auto",
          zIndex: "-1",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center bottom",
        }}
      >
        <div className="flex flex-col justify-center items-center px-20 py-2.5 font-semibold text-white bg-devRegDarkBlue border-b border-solid border-black border-opacity-10 leading-[140%] max-md:px-5">
          <div className="text-xl max-sm:text-base">
            GOVERNANCE IN PREVENTING FRAUD
          </div>
          <div className="text-lg max-sm:text-base font-extralight">
            Security <span className="font-black">·</span> Verification{" "}
            <span className="font-black">·</span> Trust
          </div>
        </div>
      </section>

      <div className="max-w-[1366px] m-auto z-2  mt-[-150px]">
        {/* <div className=" min-[1110px]:hidden"> */}
        <Swiper
          centeredSlides={true}
          spaceBetween={50}
          initialSlide={1}
          slidesPerView={slidesPerView}
          className="mySwiper px-10 max-w-[1255px] max-md:pb-16"
        >
          <SwiperSlide>
            <TopHeroCard
              title="Increased Trust"
              initialText="Showcase commitment to transparency "
              detailedText={detailTextsCard1}
              unhoveredLogo="HeroCardLogoHovered.svg"
              hoveredLogo="/HeroCardLogoHovered.svg"
              colorBg="devRegBlue"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TopHeroCard
              title="Unlimited Opportunities"
              initialText="Connecting you with potential clients"
              detailedText={detailTextsCard2}
              unhoveredLogo="/HeroCardLogo-2.svg"
              hoveredLogo="/HeroCardLogoHovered.svg"
              colorBg="devRegBlue"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TopHeroCard
              title="Community Building"
              initialText="Join a network of professionals"
              detailedText={detailTextsCard3}
              unhoveredLogo="/HeroCardLogo-3.svg"
              hoveredLogo="/HeroCardLogoHovered.svg"
              colorBg="devRegBlue"
            />
          </SwiperSlide>
        </Swiper>
        {/* </div> */}

        {/* <div className=" max-[1110px]:hidden"> */}
        {/* <TopFlipCard /> */}
        {/* </div> */}

        <div className="px-12 max-md:px-6 max-md:mt-16">
          <button
            disabled={true}
            className="py-1.5 px-6 mt-24 text-base font-medium text-devRegBlue uppercase bg-cyan-50 rounded-full max-md:mt-2 max-sm:text-sm"
          >
            You Are Who You Say You Are
          </button>

          <div className="flex justify-evenly my-3 max-sm:flex-col w-[100%]">
            <div className="flex-1 text-3xl font-semibold text-devRegDarkBlue max-md:max-w-full max-sm:text-xl">
              Bringing Credibility, Integrity, and Protection to Software
              Developers and Clients Worldwide
            </div>
            <button onClick={() => navigate("/register")} className="max-sm:w-[50%] px-5 py-2 my-3 max-lg:my-12  max-sm:my-2 max-md:my-12 text-white hover:text-devRegDarkBlue bg-devRegDarkBlue hover:bg-white hover:border-2 hover:font-bold hover:border-devRegDarkBlue rounded-full max-md:px-4 max-sm:py-2 max-sm:px-2 max-sm:text-base whitespace-nowrap">
              Get Verified Now!
            </button>
          </div>
        </div>

        <MidHeroCard />
        <ThreeStepRegistration />
        <UnlockingOpportunities />
      </div>
      <BecomeRecognized />
    </div>
  );
}

export default Home;
