import React from "react";

function HowItWorks() {
  const cardData = [
    {
      img: "/RequirementsCardLogo-1.svg",
      title: "Registration",
      desc: `Start by accurately completing the registration form, ensuring all
      necessary documents are ready. Upload two government IDs
      (Passport, Driver's License, or National ID) and a recent utility
      bill or statement with your address (no PO Boxes). For other valid
      documents, contact the admin`,
    },
    {
      img: "/RequirementsCardLogo-2.svg",
      title: "Facial Capture for Identification",
      desc: "Utilize your mobile phone to perform a facial capture, which utilizes advanced facial recognition software. This technology is compatible with video ID verification and supports a broad range of ID documents from 153 countries, ensuring a wide-reaching and convenient identification process.",
    },
  ];
  const cardData2 = [
    {
      img: "/RequirementsCardLogo-3.svg",
      title: "Zoom Interview Scheduling",
      desc: "Select a suitable date and time for a 15-minute Zoom interview using our interactive calendar. You'll be meeting with your designated country manager for this brief session.",
    },
    {
      img: "/RequirementsCardLogo-4.svg",
      title: "Account Approval",
      desc: "Once your account is approved, you will have exclusive access to all features on our website, tailored specifically for you.",
    },
  ];

  return (
    <div className="max-w-main m-auto px-10 max-md:px-3 mt-16">
      <div className="font-bold text-2xl md:text-3xl text-devRegDarkBlue mb-8">
        How it works?
      </div>

      <div className="grid gap-8 md:gap-18 grid-cols-1 md:grid-cols-2 max-sm:px-0">
        {cardData.map((item, index) => (
          <div
            className="flex flex-col md:flex-col justify-start items-start gap-5 bg-white rounded-lg p-6 border border-[#E5E7EB]"
            key={index}
          >
            <img
              src={item.img}
              alt={item.title}
              className="w-12 md:w-14 mt-2 md:mt-0"
            />
            <div className="text-devRegBlack">
              <div className="font-bold text-lg sm:text-xl md:text-2xl">
                {item.title}
              </div>
              <p className="text-base md:text-lg mt-4">{item.desc}</p>
            </div>
          </div>
        ))}
        {cardData2.map((item, index) => (
          <div
            className="flex flex-col md:flex-col justify-start items-start gap-5 bg-white rounded-lg p-6 border border-[#E5E7EB]"
            key={index}
          >
            <img
              src={item.img}
              alt={item.title}
              className="w-12 md:w-14 mt-2 md:mt-0"
            />
            <div className="text-devRegBlack">
              <div className="font-bold text-lg sm:text-xl md:text-2xl">
                {item.title}
              </div>
              <p className="text-base md:text-lg mt-4">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="text-base md:text-lg mt-14 w-auto mx-auto sm:px-2">
        Please be assured that all documents and identification information provided during registration are securely stored on an offline computer and completely removed from our server. Each individual's data is identified solely by a unique serial number. The only information retained online is what you choose to include in your personal profile for self-promotion purposes.
      </div>
    </div>
  );
}

export default HowItWorks;