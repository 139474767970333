import * as React from "react";
import { useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function InterviewProcess() {
  const navigate = useNavigate();

  const [checklistItems, setChecklistItems] = useState([
    { checked: false, text: "Ensure you have identification documents ready and accessible." },
    { checked: false, text: "Be available for a 10 to 15-minute video interview." },
    { checked: false, text: "Use a device with a clear camera and microphone." },
    { checked: false, text: "Be ready to respond promptly to all instructions during the interview." }
  ]);

  const toggleChecklistItem = (index) => {
    setChecklistItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const allChecked = checklistItems.every((item) => item.checked);

  return (
    <div className="flex overflow-hidden flex-col bg-gray-50 min-h-screen">
      <div className="flex flex-col items-center w-full max-md:max-w-full">
        <div className="flex flex-col max-w-[1440px] max-md:max-w-full">
          <div className="flex flex-col px-32 py-12 w-full max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col justify-center pb-6 w-full border-b border-solid max-md:max-w-full">
              <div className="text-2xl font-bold text-gray-800 max-md:max-w-full">
                Welcome to DevReg
              </div>
              <div className="mt-2 text-base leading-6 text-gray-600 max-md:max-w-full">
                You will undergo a short video interview as part of our
                verification process. This interview is an important step in
                confirming your identity and ensuring the authenticity of the
                information you’ve provided.
              </div>
            </div>
            <div className="flex flex-col mt-6 w-full max-md:max-w-full">
              <div className="flex flex-col justify-center w-full max-md:max-w-full">
                <div className="text-base font-semibold text-gray-700 max-md:max-w-full">
                  1. Interview Overview
                </div>
                <div className="flex flex-col mt-2 w-full text-sm max-md:max-w-full">
                  <div className="flex flex-wrap gap-1 items-center w-full max-md:max-w-full">
                    <div className="self-stretch my-auto font-medium text-gray-700">
                      Duration:{" "}
                    </div>
                    <div className="self-stretch my-auto text-gray-500">
                      The interview will take approximately 10 to 15 minutes.
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-1 items-center w-full max-md:max-w-full">
                    <div className="self-stretch my-auto font-medium text-gray-700">
                      Platform:
                    </div>
                    <div className="self-stretch my-auto text-gray-500 max-md:max-w-full">
                      You will receive a meeting link for the video interview on
                      our secure platform (e.g., Jitsi).
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-1 items-center w-full max-md:max-w-full">
                    <div className="self-stretch my-auto font-medium text-gray-700">
                      Purpose:
                    </div>
                    <div className="self-stretch my-auto text-gray-500 max-md:max-w-full">
                      The purpose of this interview is to verify the details you
                      have provided and confirm your identity. Please ensure you
                      are prepared to follow the interviewer’s instructions.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center mt-8 w-full text-gray-500 max-md:max-w-full">
                <div className="text-base font-semibold text-gray-700 max-md:max-w-full">
                  2. What to Expect During the Interview
                </div>
                <div className="mt-2 text-sm leading-5 max-md:max-w-full">
                  During the interview, our manager will ask you to follow
                  certain instructions. This will involve confirming your
                  identity and verifying key details. The interview process is
                  designed to be straightforward, but you must be ready to
                  respond immediately to the manager's requests.
                </div>
                <div className="flex-1 shrink gap-2.5 self-stretch p-4 mt-2 w-full text-xs leading-5 bg-gray-100 rounded-lg border border border-solid max-md:max-w-full">
                  <span className="font-medium text-gray-700">Important</span>:
                  <ul className="list-disc ml-5">
                    <li>
                      Ensure you have identification documents and proof of
                      address available and easily accessible during the
                      interview. The interviewer may request these documents
                      during the call.
                    </li>
                    <li>
                      Be prepared to follow specific instructions provided by
                      the manager to ensure the verification process is smooth
                      and efficient.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-col justify-center mt-8 w-full text-gray-500 max-md:max-w-full">
                <div className="text-base font-semibold text-gray-700 max-md:max-w-full">
                  3. After the Interview
                </div>
                <div className="mt-2 text-sm leading-5 max-md:max-w-full">
                  Once the interview is complete:
                  <br />
                  Document Security: All documents presented during the
                  interview will be securely stored in an encrypted format. Your
                  personal information, apart from your name and assigned serial
                  number, will be deleted from our system.
                </div>
                <div className="flex-1 shrink gap-2.5 self-stretch p-4 mt-2 w-full text-xs leading-5 bg-gray-100 rounded-lg border border border-solid max-md:max-w-full">
                  <span className="font-medium text-gray-700">Next Steps:</span>
                  <br />
                  If everything is verified, you will be added to our verified
                  database. If further verification is required, you may be
                  invited for a follow-up interview.
                </div>
              </div>
              <div className="flex flex-col justify-center mt-8 w-full text-sm max-md:max-w-full">
                <div className="text-base font-semibold text-gray-700 max-md:max-w-full">
                  4. Key Reminders
                </div>
                <div className="mt-2 leading-5 text-gray-500 max-md:max-w-full">
                  <span className="font-medium text-gray-700">
                    Be Honest and Transparent
                  </span>
                  : It is crucial that all the information and documents you
                  provide are accurate. Any discrepancies or suspicious
                  behaviour may lead to additional verification steps or
                  disqualification from the process.
                </div>
                <div className="flex flex-wrap gap-1 items-center mt-2 w-full max-md:max-w-full">
                  <div className="self-stretch my-auto font-medium text-gray-700">
                    Stay Ready:{" "}
                  </div>
                  <div className="self-stretch my-auto text-gray-500 max-md:max-w-full">
                    Keep your identification and other necessary documents on
                    hand during the interview to avoid any delays.
                  </div>
                </div>
                <div className="flex flex-wrap gap-1 items-center mt-2 w-full max-md:max-w-full">
                  <div className="self-stretch my-auto font-medium text-gray-700">
                    Listen Carefully:{" "}
                  </div>
                  <div className="self-stretch my-auto text-gray-500 max-md:max-w-full">
                    Follow the manager’s instructions during the interview and
                    respond promptly.
                  </div>
                </div>
                <div className="mt-2 text-gray-700 max-md:max-w-full">
                  We look forward to verifying your identity and welcoming you
                  to{" "}
                  <span className="font-semibold text-gray-700">
                    DevReg
                  </span>
                  !
                </div>
              </div>
              <div className="flex flex-col justify-center pt-6 mt-8 w-full border-t border-solid max-md:max-w-full">
                <div className="text-base font-semibold text-gray-700 max-md:max-w-full">
                  Quick Checklist for Applicants
                </div>
                <ul className="flex flex-col items-start justify-start max-md:justify-center self-start mt-4 text-sm text-gray-600 max-md:max-w-full">
                  {checklistItems.map((item, index) => (
                    <li
                      key={index}
                      className="flex gap-1 items-center mt-2.5 max-md:max-w-full cursor-pointer"
                      onClick={() => toggleChecklistItem(index)}
                    >
                      <div
                        className={`flex shrink-0 self-stretch max-md:my-auto w-4 h-4 rounded border border-[#9CA3AF] ${item.checked ? "bg-blue-900" : "bg-[#F3F4F6]"}`}
                      />
                      <div className="self-stretch my-auto text-sm">
                        {item.text}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex flex-col justify-center items-end mt-6 w-full text-base font-medium text-gray-50 whitespace-nowrap max-md:max-w-full">
              <button onClick={() => { navigate("/interview"); window.scrollTo(0, 0); }} className={`flex gap-1 justify-center items-center py-2.5 pr-4 pl-5 w-40 max-w-full bg-blue-900 rounded-[99px] max-md:w-full ${allChecked ? "bg-blue-900" : "bg-gray-400 cursor-not-allowed"}`}
                disabled={!allChecked}>
                <div className="self-stretch my-auto">Proceed</div>
                <BsArrowRightShort size={25} className="object-contain shrink-0 self-stretch my-auto aspect-square" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InterviewProcess;
