import React from "react";
import TopBanner from "../components/TopBanner";
import BecomeRecognized from "../components/BecomeRecognized";

function PrivacyMain() {
  return (
    <div className="bg-[#F9FAFB]">
      <TopBanner imgBg={"/PrivacyPolicyHeader-BG.png"} center={false} />
      <div className="max-w-main m-auto px-10 max-md:px-3 mt-16">
        <div className="text-3xl font-bold tracking-tight leading-9 text-blue-900 max-md:max-w-full">
          PRIVACY POLICY
        </div>
        <div className="flex flex-col py-4 mt-4 max-md:max-w-full bg-white p-6 rounded-xl">
          <div className="flex flex-col pb-4 max-md:max-w-full">
            <div className="text-2xl font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full">
              Introduction
            </div>
            <div className="mt-4 text-base leading-7 text-black70 font-bold max-md:max-w-full">
              Welcome to Blockchain Management Corporation Pty Ltd (“we,” “our,”
              or “us”). Protecting your privacy is a top priority for us. In
              this Privacy Policy, we aim to explain in detail how we handle
              your information, both personal and non-personal, when you use our
              website or services.
            </div>
          </div>
          
          <div className="flex flex-col pb-4 mt-6 max-md:max-w-full">
            <div className="text-2xl text-blue-900 font-bold tracking-tight leading-7 max-md:max-w-full">
              Information We Collect
            </div>
            <div className="mt-4 text-base leading-7 text-zinc-600 max-md:max-w-full">
              <span className="font-bold">
                We collect two types of information:
              </span>
              <br />
              <div className="text-black70 font-bold">
                Personal Information When you interact with our website or
                services, you may provide us with personal information. This
                includes your name, email address, postal address, phone number,
                and other details you provide through forms or when interacting
                with our services.
              </div>
              <br />
              <div className="text-black70 font-bold">
                Non-Personal Information In addition to personal information, we
                gather non-personal information. This may include your IP
                address, browser type, the specific pages you visit, the date
                and time of your access, and the website that referred you to
                ours.
              </div>
            </div>
          </div>
          
          <div className="flex flex-col pb-4 mt-6 max-md:max-w-full">
            <div className="text-2xl text-blue-900 font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full">
              How We Use Your Information
            </div>
            <div className="mt-4 text-base leading-7 font-bold text-black70 max-md:max-w-full">
              We utilise the collected information for several purposes:
              <ul>
                <li className="text-black70 font-bold">
                  <span className="font-bold text-black70">
                    ● Providing and Maintaining Services:{" "}
                  </span>
                  We use your information to ensure our services function
                  properly and to offer you the best user experience.
                </li>
                <li className="text-black70 font-bold">
                  <span className="font-bold text-black70">
                    {" "}
                    ● Communication:{" "}
                  </span>
                  If you subscribe, we may send you updates and newsletters.
                  Additionally, we use your information to respond to your
                  inquiries and comments.
                </li>
                <li className="text-black70 font-bold">
                  <span className="font-bold text-black70">
                    {" "}
                    ● Improvement:{" "}
                  </span>
                  We analyze the data to improve our services, making them more
                  effective and user-friendly.
                </li>
              </ul>
            </div>
          </div>
          
          <div className="flex flex-col pb-4 mt-6 max-md:max-w-full">
            <div className="text-2xl text-blue-900 font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full">
              Disclosure of your Information
            </div>
            <div className="mt-4 text-base leading-7 text-black70 font-bold max-md:max-w-full">
              We want you to know that we do not sell, trade, or rent your
              personal information to third parties. However, we may share your
              information with trusted third-party service providers who assist
              us in operating and maintaining our services. These third parties
              are bound by confidentiality agreements to safeguard your data.
            </div>
          </div>
          
          <div className="flex flex-col pb-4 mt-6 max-md:max-w-full">
            <div className="text-2xl font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full">
              Cookies and Tracking Technologies
            </div>
            <div className="mt-4 text-base leading-7 text-black70 font-bold max-md:max-w-full">
              To enhance your experience, we employ cookies and similar tracking
              technologies. You can manage cookie preferences through your
              browser settings.
            </div>
          </div>
          
          <div className="flex flex-col pb-4 mt-6 max-md:max-w-full">
            <div className="text-2xl font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full">
              Security
            </div>
            <div className="mt-4 text-base leading-7 text-black70 font-bold max-md:max-w-full">
              We take reasonable measures to protect your information against
              unauthorised access and disclosure. However, it’s important to
              understand that no data transmission over the internet is
              completely secure.
            </div>
          </div>
          
          <div className="flex flex-col pb-4 mt-6 max-md:max-w-full">
            <div className="text-2xl font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full">
              Changes to This Privacy Policy
            </div>
            <div className="mt-4 text-base leading-7 text-black70 font-bold max-md:max-w-full">
              This Privacy Policy may be updated periodically. Any revisions
              will be posted on our website, and the latest revision date will
              be displayed at the top of this page.
            </div>
          </div>
          
          <div className="flex flex-col pb-4 mt-6 max-md:max-w-full">
            <div className="text-2xl font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full">
              Contact Us
            </div>
            <div className="mt-4 font-bold text-base leading-7 text-black70 max-md:max-w-full">
              This Privacy Policy may be updated periodically. Any revisions
              will be posted on our website, and the latest revision date will
              be displayed at the top of this page.
              <br />
              <br />
              If you have any questions about this Privacy Policy or your
              information, please feel free to contact us at{" "}
              <a
                href="https://register.dev/contact"
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
              <br />
              <span className="font-bold">Last Updated:</span> September 18,
              2023
              <br />
              Please note that this Privacy Policy is subject to change without
              notice.
            </div>
          </div>
        </div>
      </div>
      <BecomeRecognized />
    </div>
  );
}

export default PrivacyMain;