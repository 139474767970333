import React, { useState, useEffect } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate, useLocation  } from "react-router-dom";
import WebSocketHandler from "../../services/webSocketHandler";
import {
  checkEmailVerification,
} from "../../api/apiCall";

function RegistrationSuccess() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sn = queryParams.get('sn');

  const navigate = useNavigate();
  const [iShouldRedirect, setIShouldRedirect] = useState(false);
  const [iRedirectTo, setIRedirectTo] = useState('');

  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   const { id, email } = user || { id: 0, fullname: '' };

  //   const wsHandler = new WebSocketHandler(
  //     `${process.env.REACT_APP_WEBSOCKET_URL}?socket=1&id=${id}&name=${email}`,
  //     (message) => {
  //       if (message === 1) { 
  //         user.is_verified = true;
  //         localStorage.setItem('userData', JSON.stringify(user));
          
  //         setIShouldRedirect(true);
  //         setIRedirectTo('/personal-information');
  //       }
  //     }
  //   );

  //   if (id > 0) wsHandler.connect();

  //   return () => {
  //     if (wsHandler) wsHandler.disconnect();
  //   };
  // }, []);

  // useEffect(() => {
  //   if (iShouldRedirect && iRedirectTo) {
  //     navigate(iRedirectTo);
  //   }
  // }, [iShouldRedirect, iRedirectTo, navigate]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleCheckEmailVerification();
    }, 1000);

    return () => clearInterval(intervalId);

  }, []);

  const handleCheckEmailVerification = async () => {
    try{
      let data = {
        id: user.id,
        token: token,
      }

      const response = await checkEmailVerification(data);
      if(response.data.user.is_verified){
        navigate(`/personal-information?sn=${sn}`);
      }
    }
    catch(error){
      console.log("Error checking email verification: ", error);
    }
  }

  return (
    <div className="bg-[#F9FAFB] py-14 h-screen">
      <div className="h-[30vh] px-10 max-md:px-5">
        <div className="flex justify-center items-center flex-col mx-auto mt-20">
          <div className="flex justify-center items-center flex-col max-w-[730px] border border-gray-200 p-6 rounded-lg bg-white">
            <div className="flex items-center justify-center gap-3 max-md:text-center max-md:flex-col">
              <FaRegCheckCircle className="text-[45px] text-[#0082BA]"/>
              <div className="flex-1 text-[30px] font-extrabold text-[#1c3775] max-md:text-[24px]">
                Thank you for your registration!
              </div>
            </div>
            <div className="mt-4 text-center max-md:text-sm">
              Thank you for signing up here at DevReg. Please check your email for the
              confirmation of this registration and get back here.
            </div>
            {/* <div className="w-[80%] m-auto">
              <button
                onClick={() => navigate("/login")}
                className="mt-7 w-full m-auto bg-[#1c3775] text-white px-7 py-2 rounded-full font-semibold text-lg "
              >
                Close This Page
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationSuccess;
